<template lang="html">
  <div class="container-item">
      <span v-html="itemHtml">{{itemHtml}}</span>
      <span>{{item.iso_code}}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    itemHtml: { required: true }
  }
}
</script>

<style>
  .container-item{
    display: flex;
    -webkit-display: flex;
    -ms-display: flex; /* IE 9 */
    -moz-display: rflex; /* Firefox */
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
  }
</style>