<template>
  <div>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" class="mb-4">
            <div class="one__quote"  @click="goToStep(3)">
              Your Quote<br />
              {{
                this.oneData
                  ? this.oneData.response.ticket.total_charge_format
                  : ""
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="goToStep(3)"
              >HIRE US NOW</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="goToStep(1)"
              >Go Back</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    goToStep(n) {
      this.$emit("goToStep", n);
    },
  },
  computed: {
    ...mapGetters(["oneData"]),
  },
};
</script>
