<template>
  <div>
    <h1 class="step__title">Review And Pay</h1>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-expansion-panels accordion v-model="expended_value">
              <v-expansion-panel id="payment_0">
                <v-expansion-panel-header>Ticket Information
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br />
                  <br />
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-select :items="citationTypeList" filled label="Type Citation" v-model="citationType"
                        item-text="name_en" item-value="id" return-object readonly></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Number of Tickets" v-model="quantity" filled readonly></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-btn class="step__scan__buttonred" block @click="goToStep(1)">Review or Edit Ticket</v-btn>
                    </v-col>
                  </v-row>
                  <br />
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel id="payment_1">
                <v-expansion-panel-header>Fee Details
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br />
                  <br />
                  <v-row>
                    <v-col cols="12" sm="12" class="tarifas" v-if="oneData && oneData.response">
                      <div class="tarifas__block" v-for="(violation, ind) in oneData.response.violations"
                        :key="`violation_${ind}`">
                        <div class="tarifas__block__left">
                          {{ violation.type_of_violation }}
                        </div>
                        <div class="tarifas__block__right">
                          $ {{ violation.price }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <br />
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel id="payment_2">
                <v-expansion-panel-header>Coupon Code
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br />
                  <br />
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Coupon" v-model="cupon" filled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-btn class="step__scan__buttonred" block @click="getCupon">Apply Coupon</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12"
                      :class="cuponData != null && cuponMessage != '' ? 'step__cupon__text' : 'step__cupon__error'">
                      {{ cuponMessage }}
                    </v-col>
                  </v-row>
                  <br />
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel id="payment_3">
                <v-expansion-panel-header>Payment Information
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br />
                  <br />

                  <!--v-row>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="tarjetas"
                        filled
                        label="Choice of Credit Card"
                        v-model="pago.tipoTarjeta"
                        :error-messages="pagoTipoTarjeta"
                        @change="$v.pago.tipoTarjeta.$touch()"
                        @blur="$v.pago.tipoTarjeta.$touch()"
                      ></v-select>
                    </v-col>
                  </v-row-->
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Credit Card Number" v-model="pago.tarjeta" filled :error-messages="pagoTarjeta"
                        @change="$v.pago.tarjeta.$touch()" @blur="$v.pago.tarjeta.$touch()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Expiration Date - MM/YY" v-model="pago.fechaExpiracion" filled class="pr-2"
                        :error-messages="pagoFechaExpiracion" @change="$v.pago.fechaExpiracion.$touch()"
                        @blur="$v.pago.fechaExpiracion.$touch()" v-mask="'##/##'"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="CVV Code" v-model="pago.cvv" filled class="pl-2" :error-messages="pagoCvv"
                        @change="$v.pago.cvv.$touch()" @blur="$v.pago.cvv.$touch()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Name on the Credit Card" v-model="pago.nombre" filled
                        :error-messages="pagoNombre" @change="$v.pago.nombre.$touch()"
                        @blur="$v.pago.nombre.$touch()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="12" class="d-flex justify-center">
                      <img src="@/assets/images/tarjetas.png" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <br />
                      <br />
                      <div class="card-description">
                        The Card Identification Number (Card ID) is required for
                        AMEX, Visa and Mastercard.
                      </div>
                      <br />
                    </v-col>
                  </v-row>

                  <br />
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row class="mt-14">
          <v-col cols="12" sm="12" class="card-total">
            Total Fee: ${{ totalDisplay }}
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12" sm="12" class="card-total">
            <v-checkbox class="card-disclamer" v-model="disclamer">
              <template v-slot:label>
                <div>
                  By clicking the "PAY NOW" button, you acknowledge that you have read and accepted The Ticket Clinic
                  Terms & Conditions:

                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <a target="_blank" href="https://www.theticketclinic.com/terms-and-conditions-web-eng"
                        v-bind="props" @click.stop>
                        English
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                  ,
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <a target="_blank" href="https://www.theticketclinic.com/terms-and-conditions-web-spa"
                        v-bind="props" @click.stop>
                        Spanish
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>.
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="payment">PAY NOW!</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="goToStep(3)">Go Back</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog v-model="dialog" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5 justify-center"> Success! </v-card-title>
        <v-card-text class="text-center">
          Payment information has been sent to
          {{ this.threeData ? this.threeData.email : "" }}. Thank
          You!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="onReload"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlerta" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Alert! </v-card-title>
        <v-card-text> Payment information is missing</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogAlerta = false; expended_value = 3">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogError" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Alert! </v-card-title>
        <v-card-text>{{ messageError }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogError = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcessing" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Loading... </v-card-title>
        <v-card-text>Processing Payment! Please wait. Do not close this window.</v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcessingCupon" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Loading... </v-card-title>
        <v-card-text>Processing! Please wait. Do not close this window.</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { mapGetters, mapMutations } from "vuex";
import axios from "@/axios-auth";
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

export default {
  props: {
    entry: Boolean,
  },
  data() {
    return {
      expended_value: 0,
      menu2: false,
      date: null,
      radios: null,
      tarjetas: ["AMERICAN EXPRESS", "MASTERCARD", "VISA"],
      pago: {
        tipoTarjeta: "VISA",
        tarjeta: "",
        fechaExpiracion: "",
        cvv: "",
        nombre: "",
      },
      cupon: "",
      citationTypeList: [],
      citationType: {
        allow_call: 0,
        enable_shop: 1,
        id: 1,
        message_en: null,
        message_es: null,
        name_en: "INFRACTION TICKET",
        name_es: "CITACIÓN DE INFRACCION",
        phone_call: null,
      },
      quantity: "",
      states: [],
      state: null,
      stateEmision: null,
      dialog: false,
      cuponData: null,
      totalCharge: 0,
      totalDisplay: 0,
      cuponMessage: "",
      disclamer: false,
      dialogAlerta: false,
      dialogError: false,
      dialogProcessing: false,
      dialogProcessingCupon: false,
      messageError: ""
    };
  },
  mixins: [validationMixin],
  validations: {
    pago: {
      tipoTarjeta: { required },
      tarjeta: { required },
      fechaExpiracion: { required },
      cvv: { required },
      nombre: { required },
    },
    state: { required },
  },
  methods: {
    // ...mapMutations(["setSuccessResponse"]),
    getCupon() {
      if (this.cupon) {

        this.cuponData = null;
        this.totalDisplay = this.totalCharge;
        this.cuponMessage = "";

        this.dialogProcessingCupon = true;
        let that = this;
        axios
          .post(`v1/coupon/redeem`, {
            coupon: this.cupon,
            key_process: this.oneData.response.key_process
          })
          .then((response) => {
            return response.data;
          })
          .then((res) => {
            this.cupon = "";
            this.dialogProcessingCupon = false;

            if (res && res.data) {
              this.cuponData = res.data;

              this.cuponMessage = `Valid Code! $${this.cuponData.final_discount} Discount applied!`;
              this.totalDisplay = this.cuponData.final_fee;

              this.expended_value = 3;

              setTimeout(() => {
                this.$vuetify.goTo("#payment_3", {
                  duration: 0,
                  offset: 0,
                  easing: "linear",
                });
              }, 300);

            }
          })
          .catch(function (error) {

            that.cupon = "";
            that.dialogProcessingCupon = false;
            that.cuponMessage = "Invalid Code! Discount not applied!";
          });
      }
    },
    payment() {
      this.$v.pago.$touch();

      if (this.disclamer) {
        if (!this.$v.pago.$invalid) {
          let data = {
            origin: process.env.VUE_APP_ORIGIN_PLATFORM,
            address: this.threeData.direccion,
            bi_address: this.threeData.direccion,
            bi_city: this.threeData.ciudad.name,
            bi_first_name: this.threeData.name,
            bi_last_name: this.threeData.lastname,
            bi_phone: this.threeData.phone,
            bi_state: this.threeData.state.iso_code,
            bi_zipcode: this.threeData.codigoPostal,
            cc_cvv: this.pago.cvv,
            cc_exp_date: this.pago.fechaExpiracion,
            cc_name: this.pago.nombre,
            cc_number: this.pago.tarjeta,
            cc_type: this.pago.tipoTarjeta,
            city: this.threeData.ciudad.name,
            coupon: this.cuponData ? this.cuponData.code : "",
            dbo: this.threeData.dobMonth + "-" + this.threeData.dobDay + "-" + this.threeData.dobYear,
            discount: "",
            dl: this.threeData.nroLicencia,
            email: this.threeData.email,
            first_name: this.threeData.name,
            key_process:
              this.oneData && this.oneData.response
                ? this.oneData.response.key_process
                : "",
            language: "",
            last_name: this.threeData.lastname,
            sex: this.threeData.gender.code,
            lead_id:
              this.oneData && this.oneData.response
                ? this.oneData.response.lead_id.toString()
                : "",
            phone: this.threeData.phone,
            retainer_file: "",
            retainer_id: "",
            state: this.threeData.state.iso_code,
            state_issued: this.threeData.stateEmision.iso_code,
            total_tickets: this.quantity.toString(),
            type_of_citation: this.citationType.id.toString(),
            zipcode: this.threeData.codigoPostal,
          };
          this.dialogProcessing = true;
          axios
            .post(`v1/payment/save`, data, {
              headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
              this.messageError = "";
              this.dialogProcessing = false;

              if (response && response.data && response.data.status == "ok") {
                // this.dialog = true;

                this.$vuetify.goTo("#top", {
                  duration: 0,
                  offset: 0,
                  easing: "linear",
                });

                gtag('event', 'conversion', {
                  'send_to': 'AW-1068789135/5JUkCKPn5PoYEI_b0f0D',
                  'value': response.data.amount,
                  'currency': 'USD',
                  'transaction_id': ''
                });

                // this.setSuccessResponse(response.data);

                this.$router.push({ path: `/success` });
              }

              if (response && response.data && response.data.status == "error") {
                this.messageError = response.data.message;
                this.dialogError = true;

                this.$vuetify.goTo("#payment", {
                  duration: 0,
                  offset: 0,
                  easing: "linear",
                });
              }
            })
            .catch((err) => {
  
            });
        } else {
          this.dialogAlerta = true;
        }
      } else {
        this.messageError = "You must select  to Terms and Conditions Checkbox";
        this.dialogError = true;
      }
    },
    onReload() {
      this.$vuetify.goTo("#top", {
        duration: 0,
        offset: 0,
        easing: "linear",
      });
      location.reload();
      // window.location.href = process.env.VUE_APP_WEB_URL;
    },
    goToStep(n) {
      this.$emit("goToStep", n);
    },
    changePanel(panel) {
      this.expended_value = panel;
    },
    loadStates() {
      this.states = [];
      axios
        .get(`v1/states`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.states = res.data;
          }
        });
    },
    loadInfractions() {
      this.infractions = [];
      axios
        .get(`v1/infractions`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.citationTypeList = res.data;
          }
        });
    },
  },
  computed: {
    ...mapGetters(["oneData", "threeData", "stepFourData", "scanData"]),

    pagoTipoTarjeta() {
      const errors = [];
      if (!this.$v.pago.tipoTarjeta.$dirty) return errors;
      !this.$v.pago.tipoTarjeta.required &&
        errors.push("Credit Card  can't be empty.");
      return errors;
    },
    pagoTarjeta() {
      const errors = [];
      if (!this.$v.pago.tarjeta.$dirty) return errors;
      !this.$v.pago.tarjeta.required &&
        errors.push("Credit Card Number can't be empty.");
      return errors;
    },
    pagoFechaExpiracion() {
      const errors = [];
      if (!this.$v.pago.fechaExpiracion.$dirty) return errors;
      !this.$v.pago.fechaExpiracion.required &&
        errors.push("Expiration Date can't be empty.");
      return errors;
    },
    pagoCvv() {
      const errors = [];
      if (!this.$v.pago.cvv.$dirty) return errors;
      !this.$v.pago.cvv.required && errors.push("CVV Code can't be empty.");
      return errors;
    },
    pagoNombre() {
      const errors = [];
      if (!this.$v.pago.nombre.$dirty) return errors;
      !this.$v.pago.nombre.required &&
        errors.push("Name on the Credit Crad can't be empty.");
      return errors;
    },
  },
  mounted() {
    this.loadStates();
    this.loadInfractions();

    this.expended_value = 3;

  },
  watch: {
    entry(val) {


      setTimeout(() => {
        this.$vuetify.goTo("#payment_3", {
          duration: 300,
          offset: 0,
          easing: "linear",
        });
      }, 300);
      // this.$vuetify.goTo("#payment_3", {
      //   duration: 0,
      //   offset: 0,
      //   easing: "linear",
      // });
    },
    oneData(val) {
      if (val) {
        this.quantity = val.quantity;
      }
      if (val && val.response) {
        this.totalCharge = val.response.ticket.total_charge;
        this.totalDisplay = val.response.ticket.total_charge;
      }
    },
    stepFourData(val) {
      if (val && val.response) {
        this.totalCharge = val.response.ticket.total_charge;
        this.totalDisplay = val.response.ticket.total_charge;
      }
    },
    expended_value(val) {
      if (val) {
        setTimeout(() => {
          this.$vuetify.goTo("#payment_" + val, {
            duration: 300,
            offset: 0,
            easing: "linear",
          });
        }, 300);
      }
    },
  },
};
</script>
