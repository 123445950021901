<template>
  <div>
    <h1 class="step__title">Driver Information</h1>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="First Name"
              v-model="personal.name"
              filled
              :error-messages="personalName"
              @change="$v.personal.name.$touch()"
              @blur="$v.personal.name.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Last Name"
              v-model="personal.lastname"
              filled
              :error-messages="personalLastname"
              @change="$v.personal.lastname.$touch()"
              @blur="$v.personal.lastname.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              :items="genderList"
              item-text="label"
              item-value="code"
              return-object
              filled
              label="Gender"
              v-model="personal.gender"
              :error-messages="personalGender"
              @change="$v.personal.gender.$touch()"
              @blur="$v.personal.gender.$touch()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Street Address"
              v-model="personal.direccion"
              filled
              :error-messages="personalDireccion"
              @change="$v.personal.direccion.$touch()"
              @blur="$v.personal.direccion.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="mb-6">
             <vue-autocomplete 
              :items="states" 
              v-model="personal.state" 
              :get-label="getLabelState" 
              @update-items="updateItemsState"
              @item-clicked="itemClickedState"
              :min-len='0' 
              placeholder="State"
             :input-attrs="{id: 'autocompleteState'}"
              >
              </vue-autocomplete>
              <span v-show="personal.state==null && countState!=0" style="color: #ff5252 !important;font-size:0.8rem;">
                State can't be empty.
              </span>
              
            <!-- <v-autocomplete
              :items="states"
              item-text="name"
              item-value="id"
              return-object
              filled
              label="State"
              v-model="personal.state"
              :error-messages="personalState"
              @change="$v.personal.state.$touch()"
              @blur="$v.personal.state.$touch()"
            ></v-autocomplete> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="mb-6">
              <vue-autocomplete 
              :items="listCity" 
              v-model="personal.ciudad" 
              :get-label="getLabelCity" 
              @update-items="updateItemsCity"
              @item-clicked="itemClickedCity"
              :min-len='0' 
              placeholder="City"
             :input-attrs="{ id: 'autocompleteCity'}"
        
              >
              </vue-autocomplete>
              <span v-show="personal.ciudad==null && countCity!=0" style="color: #ff5252 !important;font-size:0.8rem;">
               City can't be empty.
              </span>
              
              
            <!-- <v-text-field
              label="City"
              v-model="personal.ciudad"
              filled
              :error-messages="personalCiudad"
              @change="$v.personal.ciudad.$touch()"
              @blur="$v.personal.ciudad.$touch()"
            ></v-text-field> -->
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Zip Code"
              v-model="personal.codigoPostal"
              type="number"
              filled
              :error-messages="personalCodigoPostal"
              @change="$v.personal.codigoPostal.$touch()"
              @blur="$v.personal.codigoPostal.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Phone Number"
              v-model="personal.phone"
              :error-messages="personalPhone"
              @change="$v.personal.phone.$touch()"
              @blur="$v.personal.phone.$touch()"
              filled
              v-mask="'(###) - ### - ####'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Email Address"
              v-model="personal.email"
              filled
              :error-messages="personalEmail"
              @change="$v.personal.email.$touch()"
              @blur="$v.personal.email.$touch()"
              @keyup="lowercase"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-5 mt-5">
          <v-col cols="12" sm="12" class="form-text-yellow">
            Date of Birth
          </v-col>
        </v-row>
        <v-row class="dob-box">
          <v-col cols="4">
            <v-autocomplete
              :items="months"
              filled
              label="Month"
              v-model="personal.dobMonth"
              :error-messages="personalDobMonth"
              @change="$v.personal.dobMonth.$touch()"
              @blur="$v.personal.dobMonth.$touch()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :items="days"
              filled
              label="Day"
              v-model="personal.dobDay"
              :error-messages="personalDobDay"
              @change="$v.personal.dobDay.$touch()"
              @blur="$v.personal.dobDay.$touch()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :items="years"
              filled
              label="Year"
              v-model="personal.dobYear"
              :error-messages="personalDobYear"
              @change="$v.personal.dobYear.$touch()"
              @blur="$v.personal.dobYear.$touch()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="mb-6">
              <vue-autocomplete 
              :items="statesEmision" 
              v-model="personal.stateEmision" 
              :get-label="getLabelEmision" 
              @update-items="updateItemsEmision"
              @item-clicked="itemClickedEmision"
              :min-len='0' 
              placeholder="Driver License State Issued"
              :input-attrs="{id: 'v-customer-drive-license-autocomplete'}"
              >
              </vue-autocomplete>
              <span v-show="personal.stateEmision==null && countEmision!=0" style="color: #ff5252 !important;font-size:0.71rem;">
               Driver License State Issued can't be empty.
              </span>

            <!-- <v-autocomplete
              :items="states"
              item-text="name"
              item-value="id"
              return-object
              filled
              label="Driver License State Issued"
              v-model="personal.stateEmision"
              :error-messages="personalStateEmision"
              @change="$v.personal.stateEmision.$touch()"
              @blur="$v.personal.stateEmision.$touch()"
            ></v-autocomplete> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Driver License Number"
              v-model="personal.nroLicencia"
              filled
              class="license"
              :error-messages="personalNroLicencia"
              @change="$v.personal.nroLicencia.$touch()"
              @blur="$v.personal.nroLicencia.$touch()"
              v-mask="'NNNNNNNNNNNNNNN'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="payment"
              >Continue</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn class="step__scan__button" block @click="goToStep(2)"
              >Go Back</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog v-model="dialog" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Success!! </v-card-title>
        <v-card-text>
          Payment information have been sent to {{ this.personal.email }}. Thank
          You!</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="onReload"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogError" width="400px">
      <v-card>
        <v-card-title class="text-h5">Need Special Attencion!!</v-card-title>
        <v-card-text>{{ messageError }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="dark darken-1"
            text
            @click="
              dialogError = false;
              messageError = '';
            "
          >
            OK
          </v-btn>
          <v-btn color="dark darken-1" text @click="onCallUs"> Call Us </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlerta" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Alert! </v-card-title>
        <v-card-text> Enter all the data correctly</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogAlerta = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "@/axios-auth";
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

import moment from "moment";
import AutocompleteNewVue from '../common/autocomplete/AutocompleteNew.vue';
moment.locale("en");

export default {
  components: {
    'vue-autocomplete': AutocompleteNewVue
  },
  data() {
    return {
      countEmision : 0,
      countState: 0 ,
      countCity: 0 ,
      dialogError: false,
      messageError: "",
      date: null,
      radios: null,
      personal: {
        name: "",
        lastname: "",
        gender: null,
        direccion: "",
        ciudad: null,
        state: null,
        codigoPostal: "",
        phone: "",
        email: "",
        nroLicencia: "",
        stateEmision: null,
        dobYear: null,
        dobMonth: null,
        dobDay: null,
      },
      citacion: {
        tipo: "Citación de infracción",
        numero: 1,
      },
      tarjetas: ["AMERICAN EXPRESS", "MASTERCARD", "VISA"],
      genderList: [
        {
          code: "M",
          label: "Male"
        },
        {
          code: "F",
          label: "Female"
        }
      ],

      pago: {
        tipoTarjeta: "",
        tarjeta: "",
        fechaExpiracion: "",
        cvv: "",
        nombre: "",
      },
      cityInput: null,
      tiposCitacion: ["Citación de infracción"],
      cupon: "",
      citationTypeList: [],
      citationType: null,
      quantity: "",
      listCity: [],
      listCityInit: [],
      states: [],
      statesInit: [],
      statesEmision: [],
      statesEmisionInit: [],
      state: null,
      stateEmision: null,
      dialog: false,
      cuponData: null,
      totalCharge: 0,
      disclamer: false,
      dialogAlerta: false,
      flagInputText :false,
    };
  },
  mixins: [validationMixin],
  validations: {
    personal: {
      name: { required },
      lastname: { required },
      gender: { required },
      direccion: { required },
      ciudad: { required },
      codigoPostal: { required },
      state: { required },
      phone: { required },
      email: { required, email },
      nroLicencia: { required },
      stateEmision: { required },
      dobYear: { required },
      dobDay: { required },
      dobMonth: { required },
    },
    state: { required },
  },
  methods: {
    ...mapMutations(["setThreeData"]),

    loadCity(iso_code) {
    
      this.listCity = [];
      axios
        .get(`v1/cities/${iso_code}`)
        .then((response) => {
  
          return response.data;
        })
        .then((res) => {
          if (res) {
 
            this.listCity = res.data; 

            this.listCityInit = res.data
            if(this.threeData && this.threeData.stateHome && this.threeData.cityHome){
              let foundCity = res.data.filter((e)=> {
                return  e.name.toLowerCase() == this.threeData.cityHome.toLowerCase()
              })
              console.log("foundCity 1: ",foundCity)
              if(foundCity && foundCity.length == 1){
                  this.personal.ciudad = foundCity[0];
                

              } 
            }
          
          }
        });
    },  

    getLabelState (item) {
      if(item){
        return item.name
      }
      
    },
    itemClickedState(item){
      console.log("item: ",item)
      this.listCity = [];
      this.listCityInit = [];
      this.flagInputText = true;
       this.personal.ciudad = null;

      if(item){
        this.loadCity(item.iso_code)
      }
      this.states = this.statesInit;
    },
    updateItemsState(text) {
    this.flagInputText = true;
      this.listCity = [];
      this.listCityInit = [];
      this.personal.ciudad = null;
      this.countCity++
      this.countState++;
      if(text != ''){
          this.states = this.statesInit.filter(item => {
            return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
          })
      }else{
        this.states = this.statesInit;
      }
    
    },

    getLabelCity (item) {
      if(item){
        return item.name
      }
      
    },
    itemClickedCity(item){
      this.flagInputText = false;
      this.listCity = this.listCityInit;
    },
    updateItemsCity(text) {
      this.flagInputText = false;
      this.countCity++;
      if(text != ''){
          this.listCity = this.listCityInit.filter(item => {
            return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
          })
      }else{
        this.listCity = this.listCityInit;
      }
    
    },


    getLabelEmision (item) {
      if(item){
        return item.name
      }
      
    },
    itemClickedEmision(item){
      this.statesEmision = this.statesEmisionInit;
    },
    updateItemsEmision(text) {
     
      this.countEmision++;
      if(text != ''){
          this.statesEmision = this.statesEmisionInit.filter(item => {
            return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
          })
      }else{
        this.statesEmision = this.statesEmisionInit;
      }
    
    },


    lowercase() {
      this.personal.email = this.personal.email.toLowerCase();
    },

    onCallUs() {
      this.dialogError = false;
      document.location.href = "tel:" + process.env.VUE_APP_PHONE;
    },
    payment() {
      this.countState++
      this.countCity++

      this.$v.personal.$touch();
      if (!this.$v.personal.$invalid) {
        
        let selectedDate = moment(this.personal.dobYear + "-" + this.personal.dobMonth + "-" + this.personal.dobDay);
        let momentNow = moment();

        if (Math.abs(selectedDate.diff(momentNow, "years")) >= 18) {
        

          this.setThreeData(this.personal);
          this.goToStep(4);
        } else {
          this.messageError =
            "Invalid Date of Birth. Please call us for further assistance 1-844-339-8811";
          this.dialogError = true;
          return;
        }
      } else {
    
        this.dialogAlerta = true;
      }
    },
    onReload() {
      this.$vuetify.goTo("#top", {
        duration: 0,
        offset: 0,
        easing: "linear",
      });
      location.reload();
    },
    goToStep(n) {
      this.$emit("goToStep", n);
    },
    loadStates() {
      
      this.states = [];
      axios
        .get(`v1/states`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
          
            this.states = res.data;
            this.statesInit = res.data;
            this.statesEmision = res.data;
            this.statesEmisionInit = res.data;
          
            if(this.threeData && this.threeData.stateHome){
              console.log("entro 1")
              let foundStatetest = res.data.filter((e)=> {
              return  e.name.toLowerCase() == this.threeData.stateHome.toLowerCase()
            })
            if(foundStatetest && foundStatetest.length == 1){
                this.personal.stateEmision= foundStatetest[0];
                this.personal.state = foundStatetest[0];
                this.loadCity(foundStatetest[0].iso_code)

            } 
            }
           

          } 
        });
    },
  },
  computed: { 
 
    ...mapGetters(["oneData", "stepThreeData", "stepFourData", "scanData","threeData"]),

    months(){
      return ["01","02","03","04","05","06","07","08","09","10","11","12"];
    },
    days(){
      return ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"];
    },
    years(){
      let yearStart = parseInt(moment().year() - process.env.VUE_APP_YEAR_GAP);
      let yearEnd = moment().year();
      let yearArray = [];

      for(let y = yearEnd; y >= yearStart; y--){
        yearArray.push(y);
      }

      return yearArray;
    },
    personalName() {
      const errors = [];
      if (!this.$v.personal.name.$dirty) return errors;
      !this.$v.personal.name.required && errors.push("Name can't be empty.");
      return errors;
    },

    personalLastname() {
      const errors = [];
      if (!this.$v.personal.lastname.$dirty) return errors;
      !this.$v.personal.lastname.required &&
        errors.push("Last Name can't be empty.");
      return errors;
    },
    personalGender() {
      const errors = [];
      if (!this.$v.personal.gender.$dirty) return errors;
      !this.$v.personal.gender.required &&
        errors.push("Gender can't be empty.");
      return errors;
    },

    personalDireccion() {
      const errors = [];
      if (!this.$v.personal.direccion.$dirty) return errors;
      !this.$v.personal.direccion.required &&
        errors.push("Street Address can't be empty.");
      return errors;
    },

    personalCiudad() {
      const errors = [];
      if (!this.$v.personal.ciudad.$dirty) return errors;
      !this.$v.personal.ciudad.required && errors.push("City can't be empty.");
      return errors;
    },

    personalState() {
      const errors = [];
      if (!this.$v.personal.state.$dirty) return errors;
      !this.$v.personal.state.required && errors.push("State can't be empty.");
      return errors;
    },

    personalCodigoPostal() {
      const errors = [];
      if (!this.$v.personal.codigoPostal.$dirty) return errors;
      !this.$v.personal.codigoPostal.required &&
        errors.push("Zip Code can't be empty.");
      return errors;
    },

    personalPhone() {
      const errors = [];
      if (!this.$v.personal.phone.$dirty) return errors;
      !this.$v.personal.phone.required &&
        errors.push("Phone Number can't be empty.");
      return errors;
    },
    personalEmail() {
      const errors = [];
      if (!this.$v.personal.email.$dirty) return errors;
      !this.$v.personal.email.required &&
        errors.push("Email Address can't be empty.");
      !this.$v.personal.email.email && errors.push("Email Address invalid.");
      return errors;
    },
    personalDobMonth() {
      const errors = [];
      if (!this.$v.personal.dobMonth.$dirty) return errors;
      !this.$v.personal.dobMonth.required &&
        errors.push("Month can't be empty.");
      return errors;
    },
    personalDobYear() {
      const errors = [];
      if (!this.$v.personal.dobYear.$dirty) return errors;
      !this.$v.personal.dobYear.required &&
        errors.push("Year can't be empty.");
      return errors;
    },
    personalDobDay() {
      const errors = [];
      if (!this.$v.personal.dobDay.$dirty) return errors;
      !this.$v.personal.dobDay.required &&
        errors.push("Day can't be empty.");
      return errors;
    },
    personalNroLicencia() {
      const errors = [];
      if (!this.$v.personal.nroLicencia.$dirty) return errors;
      !this.$v.personal.nroLicencia.required &&
        errors.push("Driver License Number can't be empty.");
      return errors;
    },
    personalStateEmision() {
      const errors = [];
      if (!this.$v.personal.stateEmision.$dirty) return errors;
      !this.$v.personal.stateEmision.required &&
        errors.push("Driver License State Issued can't be empty.");
      return errors;
    },

    pagoTipoTarjeta() {
      const errors = [];
      if (!this.$v.pago.tipoTarjeta.$dirty) return errors;
      !this.$v.pago.tipoTarjeta.required &&
        errors.push("Credit Card  can't be empty.");
      return errors;
    },
    pagoTarjeta() {
      const errors = [];
      if (!this.$v.pago.tarjeta.$dirty) return errors;
      !this.$v.pago.tarjeta.required &&
        errors.push("Credit Card Number can't be empty.");
      return errors;
    },
    pagoFechaExpiracion() {
      const errors = [];
      if (!this.$v.pago.fechaExpiracion.$dirty) return errors;
      !this.$v.pago.fechaExpiracion.required &&
        errors.push("Expiration Date can't be empty.");
      return errors;
    },
    pagoCvv() {
      const errors = [];
      if (!this.$v.pago.cvv.$dirty) return errors;
      !this.$v.pago.cvv.required && errors.push("CVV Code can't be empty.");
      return errors;
    },
    pagoNombre() {
      const errors = [];
      if (!this.$v.pago.nombre.$dirty) return errors;
      !this.$v.pago.nombre.required &&
        errors.push("Name on the Credit Crad can't be empty.");
      return errors;
    },
  },
  mounted() {
    console.log("threeDataname 1: ",this.threeData)
    if(this.threeData){
      this.personal={
        nroLicencia : this.threeData.nroLicencia,
        name: this.threeData.name,
        lastname: this.threeData.lastname,
        direccion: this.threeData.direccion,
        ciudad: null,
        state: null,
        dobYear: this.threeData.dobYear,
        dobMonth: this.threeData.dobMonth,
        dobDay: this.threeData.dobDay,
        gender : this.threeData.genderHome,
        codigoPostal : this.threeData.postalHome ? this.threeData.postalHome.split('-')[0] : null,
        

      }
    }
    
    this.loadStates();
  if(this.$el.querySelector('#autocompleteCity')){
          this.$el.querySelector('#autocompleteCity').value = ''
      
        }
   
  },
  updated(){
  
    if(this.flagInputText){
      this.$el.querySelector('#autocompleteCity').value = '';
    }
   
  },


  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
    oneData(val) {

      if (this.scanData) {
        if (this.scanData.city) {
          this.personal.ciudad = this.scanData.city;
        }

        if (this.states.length > 0) {
          if (this.scanData.state_abbr) {
            for (let i = 0; i < this.states.length; i++) {
              if (this.scanData.state_abbr == this.states[i].iso_code) {
                this.personal.stateEmision = {
                  id: this.states[i].id,
                  name: this.states[i].name,
                };

                this.personal.state = {
                  id: this.states[i].id,
                  name: this.states[i].name,
                };
              }
            }
          }
        }

        if (this.scanData.address_one) {
          this.personal.direccion = this.scanData.address_one;
        }

        if (this.scanData.zipcode) {
          this.personal.codigoPostal = this.scanData.zipcode;
        }

        if (this.scanData.dl_number) {
          this.personal.nroLicencia = this.scanData.dl_number;
        }
      }
    },
    stepThreeData(val) {
      if (val) {
        this.citationTypeList = val.citationTypeList;
        this.citationType = val.infraction;
        this.quantity = val.quantity;
      }
    },
    stepFourData(val) {
      if (val && val.response) {
        this.totalCharge = val.response.ticket.total_charge;
      }
    },
  },
};
</script>
