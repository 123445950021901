<template>
  <div>
    <!--h1 class="step__title">For criminal matters please call for pricing</h1-->

    <a class="step__cfp" href="tel:1-800-248-2846">
      <div class="step__cfp__text">
        For criminal matters <br>please call for pricing
      </div>
      <div class="step__cfp__phone">
        <i aria-hidden="true" class="fas fa-phone-alt mr-2"></i>
        <div class="step__cfp__phone__number">
          1-800-CITATION<br>
          <span>248-2846</span>
        </div>
      </div>
    </a>
    <v-form>
      <v-container>
        <v-row class="mb-6 top-radio" id="bullets">
          <v-col cols="12" sm="12" class="form-text-yellow text-center">
            How many tickets did you receive at this time?
          </v-col>
          <v-col cols="12" sm="12">
            <v-radio-group v-model="quantity" row>
              <v-radio :label="ind < 5 ? ind.toString() : 'Other'" :value="ind" :ripple="false"
                v-for="ind in quantities"></v-radio>
            </v-radio-group>
          </v-col>

        </v-row>
        <v-row v-if="quantity > 0 && quantity < 5">
          <v-col cols="12" sm="12">
            <v-expansion-panels accordion v-model="expended_value" v-if="tickets.length">
              <v-expansion-panel v-for="(ticket, ind) in tickets" :key="`step_${ind}`" :id="`step_${ind}`"
                :readonly="parseInt(ind) + 1 > 1 && !ticket.active">
                <v-expansion-panel-header>
                  TICKET #{{ parseInt(ind) + 1 }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br />
                  <br />
                  <v-row class="mb-2">
                    <v-col cols="12" sm="12" class="form-text-white" @click="dialogTicket = true">
                      Find type of violation
                      <span class="form-text-yellow container-tooltip">?</span>
                    </v-col>
                  </v-row>
                  <v-row class="mb-8">
                    <v-col cols="12" sm="12">
                      <vue-autocomplete :items="violationTypes" v-model="ticket.violationType"
                        :get-label="getLabelViolationTypes" @update-items="updateItemsViolationTypes(ind, $event)"
                        @item-clicked="itemClickedViolationTypes(ind, $event)" :min-len='0'
                        placeholder="Type of violation?" :input-attrs="{ id: 'v-customer-violation-autocomplete' }">
                      </vue-autocomplete>


                      <!-- <v-autocomplete
                        label="Type of violation?"
                        solo
                        :items="violationTypes"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="ticket.violationType"
                        @change="changeViolationType(ind)"
                      ></v-autocomplete> -->
                    </v-col>
                    <span class="form-text-white mt-3">Select the one that is most similar to yours or choose
                      "Other"</span>
                  </v-row>

                  <v-row v-if="ticket.speedActive">
                    <v-col cols="12" sm="12">
                      <v-text-field placeholder="Unlawful Speed - MPH" solo v-model="ticket.speed"
                        v-mask="'###'"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="ticket.speedLimitActive">
                    <v-col cols="12" sm="12">
                      <v-text-field placeholder="Posted Speed Limit - MPH" solo v-model="ticket.speedLimit"
                        v-mask="'###'"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5 mt-5" v-if="ticket.schoolZoneActive">
                    <v-col cols="12" sm="12" class="form-text-yellow">
                      School Zone or Construction Zone?
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-radio-group v-model="ticket.schoolZone" mandatory row>
                        <v-radio label="NO" :value="false" :ripple="false"></v-radio>
                        <v-radio label="YES" :value="true" :ripple="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>


                  <v-row class="mb-5 mt-5">
                    <v-col cols="12" sm="12" class="form-text-yellow">
                      Is this a Red Camera or School Bus Notice of Violation?
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-radio-group v-model="ticket.redLightCamera" row @change="methodChangeRadio">
                        <v-radio label="NO" :value="false" :ripple="false"></v-radio>
                        <v-radio label="YES" :value="true" :ripple="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12" sm="12" class="form-text-white" @click="dialogCounty = true">
                      Find County
                      <span class="form-text-yellow container-tooltip">?</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" class="mb-8">

                      <vue-autocomplete :items="counties" v-model="ticket.countie" :get-label="getLabelCounties"
                        @update-items="updateItemsCounties(ind, $event)" @item-clicked="itemClickedCounties(ind, $event)"
                        :disabled="ind > 0 ? true : false" :min-len='0' placeholder="County where ticket was received"
                        :input-attrs="{ id: 'v-customer-counties-autocomplete' + ind }">
                      </vue-autocomplete>

                      <!-- <v-autocomplete
                        label="County"
                        solo
                        :items="counties"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="ticket.countie"
                        :disabled="ind > 0? true:false"
                        @change="countyChange(ticket.countie)"
                      ></v-autocomplete> -->
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12" sm="12" class="form-text-white" @click="dialogNumber = true">
                      Find Citation number
                      <span class="form-text-yellow container-tooltip">?</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field placeholder="Citation number" solo v-model="ticket.citationNumber"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <!--v-menu
                        v-model="ticket.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="ticket.citationDate"
                            label="Date of Citation"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            solo
                          ></v-text-field>
                        </template>
<v-date-picker v-model="ticket.citationDate" @input="ticket.menu = false"></v-date-picker>
</v-menu-->

                      <date-picker v-model="ticket.citationDate" format="MM/DD/YYYY" :disabled="ind > 0 ? true : false"
                        @change="citationDateChange(ticket.citationDate)" placeholder="Date of Citation MM/DD/YYYY">
                      </date-picker>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5 mt-5">
                    <v-col cols="12" sm="12" class="form-text-yellow">
                      Were you involved in an accident?
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-radio-group v-model="ticket.involved" row>
                        <v-radio label="NO" :value="false" :ripple="false"></v-radio>
                        <v-radio label="YES" :value="true" :ripple="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5 mt-5">
                    <v-col cols="12" sm="12" class="form-text-yellow">
                      Upload a photo of your ticket
                    </v-col>
                  </v-row>
                  <v-row class="mb-5 mt-5">
                    <v-col cols="12" sm="12">
                      <div v-if="ticket.files.length" class="uploader">
                        <div class="uploader__files" v-for="file in ticket.files" :key="file.id">
                          <div class="uploader__files__file" v-if="!ticket.image">
                            {{ file.name }}
                          </div>
                          <div class="uploader__files__image" v-if="ticket.image">
                            <img :src="ticket.image.src" />

                            <div class="uploader__files__image__delete" @click="deleteFile(ind)">
                              <img src="@/assets/images/modal-close.svg" />
                            </div>
                          </div>
                          <div v-if="!ticket.image" class="uploader__files__delete" @click="deleteFile(ind)">
                            <img src="@/assets/images/modal-close.svg" />
                          </div>
                        </div>
                      </div>
                      <file-upload v-else :drop="true" v-model="ticket.files" ref="upload" @input-file="inputFile(ind)">
                        <div class="uploader">
                          <div class="uploader__box">
                            <div class="uploader__box__top">
                              <img src="@/assets/images/insert_photo.png" />Select Files or
                            </div>
                            <div class="uploader__box__bottom">
                              Drop files anywhere to upload
                            </div>
                          </div>
                        </div>
                      </file-upload>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5 mt-5">
                    <v-col cols="12" sm="12" class="form-text-yellow">
                      Is your citation due within the next five days?
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-radio-group v-model="ticket.days" row>
                        <v-radio label="NO" :value="false" :ripple="false"></v-radio>
                        <v-radio label="YES" :value="true" :ripple="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-show="!ticket.redLightCamera">
                    <v-col cols="12" sm="12">
                      <v-btn class="step__scan__button" block @click="validate(ind)">Continue</v-btn>
                    </v-col>
                  </v-row>
                  <br />
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogError" width="320px">
      <v-card>
        <v-card-title class="text-h5"> Alert! </v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="
            dialogError = false;
          dialogMessage = '';
          ">
            OK
          </v-btn>
          <v-btn color="dark darken-1" text @click="onCallUs" v-if="dialogPhone">
            Call Us
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInvolved" width="400px">
      <v-card>
        <v-card-title class="text-h5"> Need Special Attencion!! </v-card-title>
        <v-card-text>
          Your citation requires special attention, please call 1-844-339-8811
          for further assistance.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogInvolved = false">
            OK
          </v-btn>
          <v-btn color="dark darken-1" text @click="onCallUsInvolved">
            Call Us
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogRedLight" width="400px">
      <v-card>

        <v-card-text class="pt-8">
          At the moment we do not handle Notice of Violation online. For more information on your options to contest Red
          Light Camera Tickets, please call 1-800-CITATION
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogRedLight = false">
            OK
          </v-btn>
          <v-btn color="dark darken-1" text @click="onCallUsRedLight">
            Call Us
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogAllTickets" width="400px">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>
        <v-card-text>
          Were all tickets given at the same Traffic Stop?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogAllTickets = false">
            YES
          </v-btn>
          <v-btn color="dark darken-1" text @click="dialogAllTickets = false; onAllTicketsCancel();">
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAllTicketsNO" width="400px">
      <v-card>
        <v-card-title class="text-h5"> Need Special Attencion!! </v-card-title>
        <v-card-text>
          Since your tickets were given at different traffic stops, transactions need to be completed separately.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogAllTicketsNO = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCitationType" width="400px">
      <v-card>
        <v-card-title class="text-h5"> Need Special Attencion!! </v-card-title>
        <v-card-text>
          The selected Violation requires special attention. Please call our
          main line at 1-844-339-8811 to be directed to the correct
          representative.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogCitationType = false">
            OK
          </v-btn>
          <v-btn color="dark darken-1" v-if="showCall" text @click="onCallUsCitationType">
            Call Us
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDays" width="400px">
      <v-card>
        <v-card-title class="text-h5"> Need Special Attencion!! </v-card-title>
        <v-card-text>Because your citation is due within 5 days, call 1-844-339-8811 for
          immediate assitance.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="dialogDays = false">
            OK
          </v-btn>
          <v-btn color="dark darken-1" text @click="onCallUs"> Call Us </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTicket" width="600px">
      <v-card>
        <v-card-title class="text-h5"></v-card-title>
        <v-card-text>The type of violation will be located at the lower portion of your
          ticket under SECTION</v-card-text>
        <v-card-text><img src="@/assets/images/section-demo.png" class="img-border" width="100%" /></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-dialog-ok" text @click="dialogTicket = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNumber" width="600px">
      <v-card>
        <v-card-title class="text-h5"></v-card-title>
        <v-card-text>The citation number will be location toward the top right of your
          ticket.</v-card-text>
        <v-card-text><img src="@/assets/images/number-demo.png" class="img-border" width="100%" /></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-dialog-ok" text @click="dialogNumber = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCounty" width="600px">
      <v-card>
        <v-card-title class="text-h5"></v-card-title>
        <v-card-text>The County will be location toward the top left of your ticket. </v-card-text>
        <v-card-text><img src="@/assets/images/county.jpeg" class="img-border" width="100%" /></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-dialog-ok" text @click="dialogCounty = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogProcessing" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Loading... </v-card-title>
        <v-card-text>Processing! Please wait. Do not close this window.</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "@/axios-auth";

import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
const queryString = require("query-string");
import { mapMutations } from "vuex";

import FileUpload from "vue-upload-component";

import moment from "moment";
moment.locale("en");

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AutocompleteNewVue from '../common/autocomplete/AutocompleteNew.vue';

export default {
  components: {
    FileUpload,
    DatePicker,
    'vue-autocomplete': AutocompleteNewVue
  },
  data() {
    return {
      countViolationTypes: 0,
      countCounties: 0,
      valueCountie: null,
      counties: [],
      countiesInit: [],
      violationTypes: [],
      violationTypesInit: [],
      violationType: null,
      quantities: [1, 2, 3, 4, 5],
      quantity: 0,
      tickets: [],
      expended_value: 0,
      dialogCitationType: false,
      dialogInvolved: false,
      dialogRedLight: false,
      dialogAllTickets: false,
      dialogAllTicketsNO: false,
      dialogError: false,
      dialogPhone: false,
      dialogMessage: "",
      showCall: false,
      days: false,
      dialogDays: false,
      dialogTicket: false,
      dialogNumber: false,
      dialogProcessing: false,
      dialogCounty: false
    };
  },
  mixins: [validationMixin],
  validations: {
    name: { required },
    lastname: { required },
    // email: { required, email },
    // phone: { required, minLength: minLength(10), maxLength: maxLength(10) },
  },
  mounted() {
    this.loadViolationTypes();
    this.loadCounties();
    this.initTicket();
  },
  methods: {
    ...mapMutations(["setOneData"]),

    citationDateChange(obj) {

      if (this.quantity > 1) {
        for (let i = 1; i < this.quantity; i++) {
          this.tickets[i].citationDate = obj;
        }
      }
    },
    methodChangeRadio(value) {
      this.dialogRedLight = value
      // if (this.tickets[ticket].redLightCamera) {
      //   this.dialogRedLight = true;
      //   return false;
      // }

    },
    countyChange(obj) {

      if (this.quantity > 1) {
        for (let i = 1; i < this.quantity; i++) {
          this.tickets[i].countie = obj;
        }
      }
    },
    changeViolationType(ind) {

      this.dialogCitationType = false;
      this.showCall = false;

      this.tickets[ind].speed = "";
      this.tickets[ind].speedLimit = "";

      this.tickets[ind].speedActive = false;
      this.tickets[ind].speedLimitActive = false;
      this.tickets[ind].schoolZoneActive = false;

      if (!this.tickets[ind].violationType.enable_shop) {
        this.dialogCitationType = true;
      }
      if (!this.tickets[ind].violationType.allow_call) {
        this.showCall = true;
      }

      if (this.tickets[ind].violationType.display_unlawful_speed) {
        this.tickets[ind].speedActive = true;
      }
      if (this.tickets[ind].violationType.display_posted_speed_limit) {
        this.tickets[ind].speedLimitActive = true;
      }

      if (this.tickets[ind].violationType.display_school_zone) {
        this.tickets[ind].schoolZoneActive = true;
      }
    },


    getLabelViolationTypes(item) {
      if (item) {
        return item.name
      }

    },
    itemClickedViolationTypes(ind, event) {
      this.violationTypes = this.violationTypesInit;
      this.changeViolationType(ind);
    },
    updateItemsViolationTypes(ind, text) {
      this.countViolationTypes++;
      if (text != '') {
        this.violationTypes = this.violationTypesInit.filter(item => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
        })
      } else {
        this.violationTypes = this.violationTypesInit;
      }

    },



    getLabelCounties(item) {
      if (item) {
        return item.name
      }

    },

    itemClickedCounties(ind, event) {
      this.counties = this.countiesInit;
      this.countyChange(event);
    },
    updateItemsCounties(ind, text) {

      this.countCounties++;
      if (text != '') {

        this.counties = this.countiesInit.filter(item => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
        })
      } else {
        this.counties = this.countiesInit;
      }

    },
    onAllTicketsCancel() {
      this.dialogAllTicketsNO = true;
      this.expended_value = 0;
      this.quantity = 1;
      this.initTicket();

    },
    inputFile(ind) {
      this.tickets[ind].image = null;

      if (
        this.tickets[ind].files[0].file &&
        (this.tickets[ind].files[0].file.type == "image/jpeg" ||
          this.tickets[ind].files[0].file.type == "image/png")
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(this.tickets[ind].files[0].file);
        let that = this;
        reader.onload = function () {
          let image = document.createElement("img");

          image.src = reader.result;
          that.tickets[ind].image = image;
        };
      }

    },
    deleteFile(ind) {
      this.tickets[ind].image = null;
      this.tickets[ind].files = [];
    },
    saveTickets() {
      let infractionsArray = [];

      for (let i = 0; i < this.quantity; i++) {
        infractionsArray.push({
          indice: i,
          title: "Ticket #" + (parseInt(i) + 1),
          // county: "6",
          county: this.tickets[i].countie.id.toString(),
          citation_number: this.tickets[i].citationNumber,
          citation_date: moment(this.tickets[i].citationDate).format(
            "MM-DD-YYYY"
          ),
          involved_in_accident: this.tickets[i].involved ? "1" : "0",
          photo_ticket: "",
          check_unable_upload_photo_ticket: "",
          type_of_violation: this.tickets[i].violationType.id.toString(),
          unlawful_speed: this.tickets[i].speed,
          posted_speed_limit: this.tickets[i].speedLimit,
          school_zone: this.tickets[i].schoolZone ? "1" : "0",
          tickets_same_time: this.quantity > 1 ? "1" : "0",
          // tickets_same_time: this.stepThreeData.ticketsSameTime ? "1" : "0",
          cited_speed: "",
          speed_limit: "",
          are_you_the_driver: "",
          license_plate_of_vehicle: "",
        });
      }

      let formData = new FormData();

      for (let i = 0; i < this.quantity; i++) {
        if (this.tickets[i].files && this.tickets[i].files.length > 0) {
          let uri = this.tickets[i].files[0].file.name;
          let uriParts = uri.split(".");
          let fileType = uriParts[uriParts.length - 1];

          formData.append("file", this.tickets[i].files[0].file, `photo_${i}_${this.tickets[i].citationNumber}.${fileType}`)
        }
      }

      let infractions = JSON.stringify(infractionsArray);

      formData.append("infractions", infractions);
      formData.append("origin", process.env.VUE_APP_ORIGIN_PLATFORM);

      this.dialogProcessing = true;

      axios
        .post(`v1/ticket/save`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.dialogMessage = "";
          this.dialogError = false;
          this.dialogProcessing = false;

          if (response && response.data && response.data.status == "ok") {

            this.setOneData({
              data: this.tickets,
              response: response.data,
              quantity: this.quantity,
            });
            this.goToStep(2);
          }

          if (response && response.data && response.data.status == "error") {

            this.dialogMessage = response.data.message;
            this.dialogError = true;

          }

        })
        .catch((err) => {
          this.dialogProcessing = false;
        });
    },
    goToStep(n) {
      this.$emit("goToStep", n);
    },
    validate(ticket) {
      if (this.validateTicket(ticket)) {
        if (this.tickets[ticket + 1]) {
          this.tickets[ticket + 1].active = true;


          // setTimeout(() => {
          this.$vuetify.goTo(`#step_${this.expended_value}`, {
            duration: 0,
            offset: 0,
            easing: "linear",
          });
          // },500);

          this.expended_value = ticket + 1;

        }

        if (ticket == this.tickets.length - 1) {
          this.saveTickets();
        }
      }
    },
    validateTicket(ticket) {
      this.dialogMessage = "";
      this.dialogError = false;
      this.dialogPhone = false;
      if (this.tickets[ticket].countie == null) {
        this.dialogMessage = "County can't be empty.";
        this.dialogError = true;
        return false;
      }

      if (!this.tickets[ticket].citationNumber) {
        this.dialogMessage = "Citation number can't be empty.";
        this.dialogError = true;
        return false;
      }
      else {
        for (let i = 0; i < this.quantity; i++) {
          if (i != ticket) {
            if (this.tickets[i].citationNumber.toLowerCase() == this.tickets[ticket].citationNumber.toLowerCase()) {
              this.dialogMessage = "Citation number can't be duplicated.";
              this.dialogError = true;
              return false;
            }
          }
        }

      }

      if (!this.tickets[ticket].citationDate) {
        this.dialogMessage = "Date of Citation can't be empty.";
        this.dialogError = true;
        return false;
      } else {
        let selectedDate = moment(this.tickets[ticket].citationDate, "MM/DD/YYYY", true);
        let momentNow = moment();

        if (Math.abs(momentNow.diff(selectedDate, "days")) > 180) {
          this.dialogMessage =
            "Invalid Date of Citation, Please call 1-844-339-8811 for further assistance.";
          this.dialogError = true;
          this.dialogPhone = true;
          return false;
        }
      }

      //

      if (this.tickets[ticket].redLightCamera == null) {
        this.dialogMessage = "Is this a Red Camera or School Bus Notice of Violation?, please select YES or NO.";
        this.dialogError = true;
        return false;
      }

      if (this.tickets[ticket].involved == null) {
        this.dialogMessage = "Were you involved in an accident?, please select YES or NO.";
        this.dialogError = true;
        return false;
      }

      /*
            if (this.tickets[ticket].redLightCamera) {
              this.dialogRedLight = true;
              return false;
            }*/


      if (this.tickets[ticket].involved) {
        this.dialogInvolved = true;
        return false;
      }

      if (!this.tickets[ticket].violationType) {
        this.dialogMessage = "Type of Violation can't be empty.";
        this.dialogError = true;
        return false;
      } else {
        this.dialogCitationType = false;
        this.showCall = false;
        if (!this.tickets[ticket].violationType.enable_shop) {
          this.dialogCitationType = true;

          if (!this.tickets[ticket].violationType.allow_call) {
            this.showCall = true;
          }

          return false;
        }
      }

      if (this.tickets[ticket].speedActive && !this.tickets[ticket].speed) {
        this.dialogMessage = "Unlawful Speed can't be empty.";
        this.dialogError = true;
        return false;
      }

      if (
        this.tickets[ticket].speedLimitActive &&
        !this.tickets[ticket].speedLimit
      ) {
        this.dialogMessage = "Posted Speed can't be empty.";
        this.dialogError = true;
        return false;
      }

      if (
        this.tickets[ticket].speedLimitActive &&
        this.tickets[ticket].speedActive &&
        parseInt(this.tickets[ticket].speed) -
        parseInt(this.tickets[ticket].speedLimit) >
        process.env.VUE_APP_SPEEDING_GAP
      ) {
        this.dialogMessage = "Speeding 30+ is a very serious offense under FL law. Please call 1-844-339-8811 for further assistance.";
        this.dialogError = true;
        this.dialogPhone = true;
        return false;
      }


      if (this.tickets[ticket].days == null) {
        this.dialogMessage = "Is your citation due within the next five days?, please select YES or NO.";
        this.dialogError = true;
        return false;
      }

      if (this.tickets[ticket].days) {
        this.dialogDays = true;
        return false;
      }

      return true;
    },

    onCallUs() {
      this.dialogError = false;
      this.dialogPhone = false;
      this.dialogDays = false;
      this.dialogMessage = "";
      document.location.href = "tel:" + process.env.VUE_APP_PHONE;
    },
    onCallUsInvolved() {
      this.dialogInvolved = false;
      document.location.href = "tel:" + process.env.VUE_APP_PHONE;
    },
    onCallUsRedLight() {
      this.dialogRedLight = false;
      document.location.href = "tel:" + process.env.VUE_APP_PHONE;
    },
    onCallUsCitationType() {
      this.dialogCitationType = false;
      this.showCall = false;
      document.location.href = "tel:" + process.env.VUE_APP_PHONE;
    },
    changePanel(panel) {
      this.expended_value = panel;
    },
    initTicket() {
      this.tickets = [];
      for (let i = 0; i < this.quantity; i++) {
        this.tickets.push({
          countie: null,
          citationNumber: "",
          citationDate: null,
          menu: false,
          involved: null,
          files: [],
          violationType: null,
          active: false,
          speedActive: false,
          speedLimitActive: false,
          schoolZoneActive: false,
          redLightCamera: null,
          speed: "",
          speedLimit: "",
          schoolZone: false,
          days: null,
          image: null,
        });
      }
    },
    loadViolationTypes() {
      this.violationTypes = [];
      this.violationTypesInit = [];
      axios
        .get(`v1/citation_types`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.violationTypes = res.data;
            this.violationTypesInit = res.data;
          }
        });
    },
    loadCounties() {
      this.counties = [];
      axios
        // .get(`v1/counties?state=${this.stepTwoData.state.id}`)
        .get(`v1/counties?state=${process.env.VUE_APP_FLORIDA_STATE_ID}`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.counties = res.data;
            this.countiesInit = res.data;
          }
        });
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}-${day}-${year}`
    },
  },
  computed: {

    infractionErrors() {
      const errors = [];
      if (!this.$v.infraction.$dirty) return errors;
      !this.$v.infraction.required &&
        errors.push("Type Citation can't be empty.");
      return errors;
    },
    quantityErrors() {
      const errors = [];
      if (!this.$v.quantity.$dirty) return errors;
      !this.$v.quantity.required &&
        errors.push("How many violatons can't be empty.");
      return errors;
    },
    // computedDateFormatted () {
    //   return this.formatDate(this.date)
    // },
  },
  watch: {
    stepTwoData(val) {
      if (val) {
        this.loadCounties();
      }
    },
    quantity(val) {
      if (val) {

        switch (val) {
          case 1:
            this.dialogInvolved = false;
            break;

          case 2:
          case 3:
          case 4:
            this.dialogAllTickets = true;
            this.expended_value = 0;
            this.initTicket();
            break;

          case 5:
            this.dialogInvolved = true;
            break;
        }
        this.initTicket();


        setTimeout(() => {
          this.$vuetify.goTo("#bullets", {
            duration: 300,
            offset: 0,
            easing: "linear",
          });
        }, 500);
      }
    },
  },
};
</script>
