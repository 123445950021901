<template>
    <div class="page-success" id="top">
        <v-container>
            <div class="page-success__box">
                <h2>Transaction Successful!</h2>

                <p class="mb-7">Thank You! So that we may confirm via e-mail, please make sure theticketclinic.com is not in
                    your "blocked" sender list.
                    A copy of the receipt has been e-mailed to the address you provided.</p>

                <div>
                    <v-btn class="step__scan__button" block @click="onReload">
                        CONTINUE
                    </v-btn>
                </div>
                <!--p>
                    or
                </p>
                <div>
                    <v-btn
                        class="step__scan__button"
                        block>
                        CALL NOW
                    </v-btn>
                </div-->
            </div>
        </v-container>
    </div>
</template>
<script>
export default {
    // created() {
    //     console.log("gtag creado");
    //     console.log(gtag);
    //     gtag('event', 'conversion', {
    //         'send_to': 'AW-1068789135/5JUkCKPn5PoYEI_b0f0D',
    //         'value': 1.0,
    //         'currency': 'USD',
    //         'transaction_id': ''
    //     });
    // },
    methods: {
        onReload() {
            this.$vuetify.goTo("#top", {
                duration: 0,
                offset: 0,
                easing: "linear",
            });
            this.$router.push({ path: `/` });
        },
    }
}
</script>