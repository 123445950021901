<template
  class="home page-template-default page page-id-188 wp-custom-logo mega-menu-menu-1 elementor-default elementor-kit-14 e--ua-blink e--ua-chrome e--ua-mac e--ua-webkit">
    <div data-elementor-type="footer" data-elementor-id="132" class="elementor elementor-132 elementor-location-footer">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-19c9117 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="19c9117" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">

        <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-108846eb"
            data-id="108846eb" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-13ae8b51 elementor-widget__width-auto e-transform elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                data-id="13ae8b51" data-element_type="widget"
                data-settings="{&quot;_transform_scale_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.02,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
                data-widget_type="theme-site-logo.default">
                <div class="elementor-widget-container">
                  <a href="https://www.theticketclinic.com/">
                    <img width="350" height="347" src="@/assets/images/logo.png"
                      class="attachment-full size-full wp-image-8" alt="" loading="lazy"
                      srcset="@/assets/images/logo.png 350w, @/assets/images/logo-300x297.png 300w, @/assets/images/logo-150x150.png 150w"
                      sizes="(max-width: 350px) 100vw, 350px"> </a>
                </div>
              </div>
              <div class="elementor-element elementor-element-2c8aed0a elementor-widget elementor-widget-text-editor"
                data-id="2c8aed0a" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                  <p>Traffic<br>Ticket Attorney</p>
                </div>
              </div>
            </div>
          </div>
          <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3f6b7c23"
            data-id="3f6b7c23" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-722163c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="722163c9" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5185c45c"
                    data-id="5185c45c" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-2de9d061 elementor-widget elementor-widget-text-editor"
                        data-id="2de9d061" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>Contact Us</p>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-521f7c2f elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="521f7c2f" data-element_type="widget" data-widget_type="divider.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-5a08f066 elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="5a08f066" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-phone-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-7730b233 elementor-widget__width-initial linki2 elementor-widget elementor-widget-text-editor"
                        data-id="7730b233" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <a style="color: #ffe500;" href="tel:1-800-248-2846">1-800-CITATION</a>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-53735527 linki2 elementor-widget elementor-widget-text-editor"
                        data-id="53735527" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>(248-2846)</p>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-7c73c76f elementor-widget elementor-widget-spacer"
                        data-id="7c73c76f" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-5fcf888e elementor-widget elementor-widget-text-editor"
                        data-id="5fcf888e" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>Follow Us</p>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-6231d0c0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="6231d0c0" data-element_type="widget" data-widget_type="divider.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-1fc9c35e elementor-shape-circle e-grid-align-left e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons"
                        data-id="1fc9c35e" data-element_type="widget" data-widget_type="social-icons.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-social-icons-wrapper elementor-grid">
                            <span class="elementor-grid-item">
                              <a class="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-float elementor-repeater-item-b25a33a"
                                href="https://www.facebook.com/theticketclinic" target="_blank">
                                <span class="elementor-screen-only">Facebook</span>
                                <i class="fab fa-facebook"></i> </a>
                            </span>
                            <span class="elementor-grid-item">
                              <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-float elementor-repeater-item-0f3743e"
                                href="https://www.instagram.com/theticketclinic/" target="_blank">
                                <span class="elementor-screen-only">Instagram</span>
                                <i class="fab fa-instagram"></i> </a>
                            </span>
                            <span class="elementor-grid-item">
                              <a class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-float elementor-repeater-item-143468b"
                                href="https://twitter.com/theticketclinic" target="_blank">
                                <span class="elementor-screen-only">Twitter</span>
                                <i class="fab fa-twitter"></i> </a>
                            </span>
                            <span class="elementor-grid-item">
                              <a class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-float elementor-repeater-item-a5c7c03"
                                href="https://www.youtube.com/user/TheTicketClinicLaw" target="_blank">
                                <span class="elementor-screen-only">Youtube</span>
                                <i class="fab fa-youtube"></i> </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5bfbb9ed"
                    data-id="5bfbb9ed" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-3e98890f elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="3e98890f" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-10fe2ce5 elementor-widget__width-initial linki elementor-widget elementor-widget-text-editor"
                        data-id="10fe2ce5" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p><a href="https://www.theticketclinic.com/about-us">About Us</a></p>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-277da5cc elementor-widget elementor-widget-spacer"
                        data-id="277da5cc" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-21de16f elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="21de16f" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2ebd8eb4 elementor-widget__width-initial linki elementor-widget elementor-widget-text-editor"
                        data-id="2ebd8eb4" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p><a href="https://www.theticketclinic.com/ticket-clinic-locations">Locations: The Ticket Clinic
                              near you</a></p>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-36e17a8a elementor-widget elementor-widget-spacer"
                        data-id="36e17a8a" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-7416860 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="7416860" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2b88beab elementor-widget__width-initial linki elementor-widget elementor-widget-text-editor"
                        data-id="2b88beab" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p><a href="https://www.theticketclinic.com/we-fight-traffic-tickets">We fight</a></p>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-d0347d8 elementor-widget elementor-widget-spacer"
                        data-id="d0347d8" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-1641cb58 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="1641cb58" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-7d8ab650 elementor-widget__width-initial linki elementor-widget elementor-widget-text-editor"
                        data-id="7d8ab650" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p><a href="https://flwebcart.theticketclinic.com/">Hire us now</a></p>
                        </div>
                      </div>

                      <div class="elementor-element elementor-element-78c99c5c elementor-widget elementor-widget-spacer"
                        data-id="78c99c5c" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-635c5986 elementor-widget elementor-widget-spacer"
                        data-id="635c5986" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-31954a3e elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="31954a3e" data-element_type="widget" data-widget_type="icon.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-wrapper">
                            <div class="elementor-icon">
                              <i aria-hidden="true" class="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-50d9cab1 elementor-widget__width-initial linki elementor-widget elementor-widget-text-editor"
                        data-id="50d9cab1" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p><a href="https://www.theticketclinic.com/privacy-policy">Privacy Policy</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-b8c733f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="b8c733f" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b1b990d"
            data-id="4b1b990d" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-6b0cb9f4 elementor-view-default elementor-widget elementor-widget-icon"
                data-id="6b0cb9f4" data-element_type="widget" data-widget_type="icon.default">
                <div class="elementor-widget-container">
                  <div class="elementor-icon-wrapper">
                    <a class="elementor-icon elementor-animation-float" href="#top">
                      <i aria-hidden="true" class="fas fa-arrow-up"></i> </a>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-2218935b elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="2218935b" data-element_type="widget" data-widget_type="divider.default">
                <div class="elementor-widget-container">
                  <div class="elementor-divider">
                    <span class="elementor-divider-separator">
                    </span>
                  </div>
                </div>
              </div>
              <div class="elementor-element elementor-element-1d520d59 elementor-widget elementor-widget-text-editor"
                data-id="1d520d59" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                  COPYRIGHT ©2023 THE TICKET CLINIC. ALL RIGHTS RESERVED. </div>
              </div>
              <div class="elementor-element elementor-element-6479824b elementor-widget elementor-widget-text-editor"
                data-id="6479824b" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                  <p>Disclaimer: The information contained on this website and any linked resource is intended to provide
                    general information about legal matters to the public and does not constitute legal advice and should
                    not
                    be treated as such. The content is not guaranteed to be correct, complete, or up-to-date. NO
                    ATTORNEY-CLIENT RELATIONSHIP. The use of the website does not create an attorney-client relationship.
                    The
                    transmission of information via the website does not create an attorney-client relationship between
                    you
                    and The Ticket Clinic, A Law Firm. Nothing presented on the website constitutes legal advice and shall
                    not
                    be relied upon in any way. Until a signed representation and fee agreement and acceptance of the terms
                    and
                    conditions here and required payment is received and written confirmation from us, there shall be no
                    attorney-client relationship created. No legal advice is being provided. The website is maintained for
                    informational purposes only. Nothing appearing on this site shall be considered legal advice and is
                    presented only as a general statement of the law. Users of the website should not act, or decline to
                    act,
                    based on information or content from this website. The Ticket Clinic, A Law Firm is not liable for the
                    use, or interpretation, of information contained on the website or otherwise presented on accessed
                    through
                    the website, and expressly disclaims all liability for any actions you take or do not take, based on
                    the
                    website’s content. *For certain non-moving violations, ask for details. “This disclaimer governs your
                    use
                    of our website; by using our website, you accept this disclaimer in full. If you disagree with any
                    part of
                    this disclaimer, do not use our website. We reserve the right to modify these terms at any time. You
                    should therefore check periodically for changes. By using this site after we post any changes, you
                    agree
                    to accept those changes, whether or not you have reviewed them.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <div class="footer">
    <footer class="site-footer">
      <div class="container">
        <div class="contend-footer">
          <div class="grid-section">
            <div class="contact-us">
              <h4>Contact Us</h4>
              <a href="tel:1-800-248-2846">
                <div class="citation">
                  <div class="citation-first-row">
                    <img src="@/assets/images/phoneIcon.png" alt="phone icon" />
                    <p>1-800-<span>CITATION</span></p>
                  </div>
                  <div class="citation-second-row">
                    <span>(248-2846)</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="follow-us">
              <h4>Follow Us</h4>
              <div class="social-nav">
                <a
                  href="https://www.facebook.com/theticketclinic"
                  target="_blank"
                >
                  <img src="@/assets/images/facebook.png" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/theticketclinic/"
                  target="_blank"
                >
                  <img src="@/assets/images/instagram.png" alt="" />
                </a>
                <a href="https://twitter.com/theticketclinic" target="_blank">
                  <img src="@/assets/images/twitter.png" alt="" />
                </a>
                <a
                  href="https://www.youtube.com/user/TheTicketClinicLaw"
                  target="_blank"
                >
                  <img src="@/assets/images/youtube.png" alt="" />
                </a>
              </div>
            </div>
            <div class="links">
              <h4>Links</h4>
              <nav class="links-menu">
                <ul id="menu-links-menu" class="menu">
                  <li
                    id="menu-item-63"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-63
                    "
                  >
                    <a href="http://webttc.mediabytelabs.tk/contact-us/"
                      >Contact Us</a
                    >
                  </li>
                  <li
                    id="menu-item-61"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-61
                    "
                  >
                    <a
                      href="http://webttc.mediabytelabs.tk/terms-and-conditions/"
                      >Terms and conditions</a
                    >
                  </li>
                  <li
                    id="menu-item-62"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-62
                    "
                  >
                    <a href="http://webttc.mediabytelabs.tk/privacy-policy/"
                      >Privacy Policy</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
            <div class="logo">
              <img src="@/assets/images/logo-the-ticket-clinic.png" />
            </div>
          </div>
          <div class="logo logo-md">
            <img src="@/assets/images/logo-the-ticket-clinic.png" />
          </div>
          <p class="copyright">
            COPYRIGHT ©2021 THE TICKET CLINIC. ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </footer>
    <div class="disclaimer">
      <div class="container">
        <p>
          Disclaimer: The information contained on this website and any linked
          resource is intended to provide general information about legal
          matters to the public and does not constitute legal advice and should
          not be treated as such. The content is not guaranteed to be correct,
          complete, or up-to-date. NO ATTORNEY-CLIENT RELATIONSHIP. The use of
          the website does not create an attorney-client relationship. The
          transmission of information via the website does not create an
          attorney-client relationship between you and The Ticket Clinic, A Law
          Firm. Nothing presented on the website constitutes legal advice and
          shall not be relied upon in any way. Until a signed representation and
          fee agreement and acceptance of the terms and conditions here and
          required payment is received and written confirmation from us, there
          shall be no attorney-client relationship created. No legal advice is
          being provided. The website is maintained for informational purposes
          only. Nothing appearing on this site shall be considered legal advice
          and is presented only as a general statement of the law. Users of the
          website should not act, or decline to act, based on information or
          content from this website. The Ticket Clinic, A Law Firm is not liable
          for the use, or interpretation, of information contained on the
          website or otherwise presented on accessed through the website, and
          expressly disclaims all liability for any actions you take or do not
          take, based on the website's content. *For certain non-moving
          violations, ask for details. "This disclaimer governs your use of our
          website; by using our website, you accept this disclaimer in full. If
          you disagree with any part of this disclaimer, do not use our website.
          We reserve the right to modify these terms at any time. You should
          therefore check periodically for changes. By using this site after we
          post any changes, you agree to accept those changes, whether or not
          you have reviewed them."
        </p>
      </div>
    </div>
  </div> -->
</template>
<script>
import('../../assets/css/post-132-2.css')
export default {};
</script>