<template>
  <v-app>
    <topnav />
    <v-main>
      <router-view />
    </v-main>
    <footer-public />
  </v-app>
</template>

<script>

import Topnav from "@/components/navs/Topnav.vue";
import Footer from "@/components/navs/Footer.vue";
import autocompleteNew from './components/common/autocomplete/AutocompleteNew.vue'
export default {
  name: "App",
  components: {
    Topnav,
    "footer-public": Footer,
    "v-autocomplete" : autocompleteNew
  },
  data: () => ({
    //
  }),
};
</script>
