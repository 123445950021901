<template>
  <div class="home" id="top">
    <div class="text-center pa-4">
      <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen persistent>
        <v-card class="container-dialog-scanner">
          <document-reader class="container-reader" @document-reader="listener" ref="component"
            v-if="isOpen"></document-reader>

        </v-card>


      </v-dialog>
    </div>

    <!--     
    <div class="container-scaner-v1" v-if="(!selectState && !isNationwide) && isMobile  && !completeScan">
        
        <div >
          <document-reader 
          style="height: 500px;"  
          @document-reader="listener"
          ref="component" 
          v-if="isOpen"></document-reader> 

       
      
        </div>
      </div> -->


    <div v-if="showScan">
      <div class="container-scaner" v-if="(!selectState && !isNationwide) && isMobile && !completeScan">
        <div class="container-loader" v-show="loading">
          <span class="loader"></span>
        </div>

        <div class="home__top" v-if="!isOpen">
          Every case is different. Complete the steps and our app will calculate
          the fee.
        </div>
        <div>

          <div class="step" v-if="!isOpen">
            <h1 class="step__title" style="margin-bottom: 35px !important;">Scan Driver´s License</h1>
            <div class="home__top">
              Scanning your driver´s lincese will speed up the process of gathering basic information. Please scan the
              2D barcode on the back of your license.
            </div>

            <div class="camera-container">
              <div class="overlay-camera-1">
                <img src="@/assets/images/barcode.png" />
              </div>
            </div>
            <div class=" form-text-yellow text-center">
              2D Barcode
            </div>
            <div class="home__top">
              Contains Identification Data
            </div>
            <v-row>
              <v-col cols="12" sm="12" class="mt-8">
                <v-btn class="step__scan__button" block @click="openDialogScanner()">SCAN NOW</v-btn>
              </v-col>
            </v-row>
            <div class="container-skip-scan">
              <a class="" @click="goToStep(1)">Skip scan</a>
            </div>

          </div>

        </div>
      </div>
    </div>

    <v-container>


      <!--scan v-if="scanShow" @goToStep="goToStep" /-->
      <div class="steps-items" v-if="selectState && !isNationwide">
        <div class="home__top">
          Every case is different. Complete the steps and our app will calculate
          the fee.
        </div>
        <div class="v-stepper__content">

          <div class="step">
            <h1 class="step__title">In which state did you get your ticket?</h1>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <vue-customer-autocomplete :items="states" v-model="state" :get-label="getLabel"
                      @update-items="updateItems" @input="state = $event" @item-clicked="itemClicked"
                      :input-attrs="{ id: 'autocompleteStateHome' }" :min-len='0' placeholder="Select States">
                    </vue-customer-autocomplete>
                    <span v-show="state == null && count != 0" style="color: #ff5252 !important;font-size:0.71rem;">
                      State issued can't be empty.
                    </span>

                  </v-col></v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="mt-8">
                    <v-btn class="step__scan__button" block @click="submit">Continue</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </div>
      </div>


      <div>

      </div>
      <nationwide v-if="isNationwide && completeScan" />

      <!--v-stepper alt-labels class="steps" v-model="step" v-else-->
      <v-stepper alt-labels class="steps" v-model="step" v-if="!selectState && !isNationwide && completeScan">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="step > 1"> 1 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="step > 2"> 2 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3"> 3 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4"> 4 </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="steps-items">
          <v-stepper-content step="1" class="step">
            <one @goToStep="goToStep"></one>
          </v-stepper-content>
          <v-stepper-content step="2">
            <two @goToStep="goToStep" />
          </v-stepper-content>
          <v-stepper-content step="3">
            <three @goToStep="goToStep" />
          </v-stepper-content>
          <v-stepper-content step="4">
            <five @goToStep="goToStep" :entry="entry" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>

    <div class="home__bottom">
      <img src="@/assets/images/padlock.png" class="home__bottom__padlock" />
    </div>



    <v-dialog v-model="dialogError" width="320px" persistent>
      <v-card>
        <v-card-title class="text-h5"> Alert! </v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="
            dialogError = false;
          dialogMessage = '';
          countError == 3 ? completeScan = true : completeScan = false;
          isOpen = false;
          ">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import('../assets/css/frontend-lite.min.css')
import('../assets/css/fontawesome.min.css')
import('../assets/css/solid.min.css')
import('../assets/css/brands.min.css')
import('../assets/css/animations.min.css')


import Scan from "../components/steps/Scan";
import Nationwide from "../components/steps/Nationwide";

import One from "../components/steps/One";
import Two from "../components/steps/Two";
import Three from "../components/steps/Three";
import Five from "../components/steps/Five";
import { isMobileOnly } from "mobile-device-detect";

import axios from "@/axios-auth";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

import { mapMutations, mapGetters } from "vuex";
import autocompleteNew from '../components/common/autocomplete/AutocompleteNew.vue'
import { defineComponents, DocumentReaderService, } from '@regulaforensics/vp-frontend-document-components';
import {
  DocumentReaderApi,
  GraphicFieldType,
  Configuration,
  Scenario, TextFieldType
} from '@regulaforensics/document-reader-webclient';

const configuration = new Configuration({ basePath: 'https://nightly-api.regulaforensics.com' });
const api = new DocumentReaderApi(configuration);


export default {
  name: "Home",

  components: {
    Scan,
    One,
    Two,
    Three,
    Five,
    Nationwide,
    'vue-customer-autocomplete': autocompleteNew
  },
  mixins: [validationMixin],
  validations: {
    state: { required },
  },
  data() {
    return {
      showScan: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: false,

      countError: 0,
      dialogMessage: '',
      dialogError: false,
      entry: false,
      step: 1,
      count: 0,
      scanShow: false,
      state: null,
      states: [],
      statesInit: [],
      selectState: false,
      isNationwide: false,
      isOpen: false,
      isMobile: false,
      dataScan: null,
      completeScan: false,
      statusProcess: '',
      buttonDisabled: false,
    };
  },
  methods: {

    ...mapMutations(["setStateSelected", "setThreeData"]),
    created() {

    },

    openDialogScanner() {
      !this.buttonDisabled ? this.dialog = true : this.dialog = false;
      setTimeout(() => {
        !this.buttonDisabled ? this.isOpen = true : this.isOpen = false;
      }, 1000)
    },
    async listener(data) {
      // console.log("data: ",data)
      this.buttonDisabled = true;

      if (data.detail?.action === 'PROCESS_FINISHED') {
      this.loading = true;
      //console.log("data.detail: ",data.detail)
        const status = data.detail.data?.status;
        const isFinishStatus = status === 1 || status === 2;
        // console.log("data.detail.data.response : ",data.detail.data.response);

        if (data.detail.data?.reason == "NO_CAMERA" || data.detail.data?.reason == "CAMERA_PERMISSION_DENIED") {
          this.countError++;
          this.dialogMessage = 'The camera is not available';
          this.dialogError = true;
          this.isOpen = false;
          this.dialog = false;
          this.buttonDisabled = false;
          this.loading = false;
          return
        }

        if (!isFinishStatus || !data.detail.data?.response) return;
        this.isOpen = false;
        this.dialog = false;
        const componentResponse = data.detail.data.response;
        // console.log("componentResponse: ",componentResponse)
        if (componentResponse) {
          const imageField = componentResponse.images.getField(GraphicFieldType.DOCUMENT_FRONT);
          const documentFront = imageField.valueList[1].value;
          const processParam = {
            images: [documentFront],
            processParam: {
              scenario: Scenario.BARCODE,
            }
          }
          const result = await api.process(processParam);

          if (!result.text) {
            this.countError = 1
            this.dialogMessage = 'Unable to capture. Please make sure there is good illumination and no glare on the image (code: 01)';
            this.dialogError = true;
            this.buttonDisabled = false;
            this.dialog = false;
            this.loading = false;
            return
          }
          
          if (!result.text.getFieldValue(TextFieldType.SURNAME) || !result.text.getFieldValue(TextFieldType.FIRST_NAME) || !result.text.getFieldValue(TextFieldType.ADDRESS) || !result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) || !result.text.getFieldValue(TextFieldType.ADDRESS_CITY) || !result.text.getFieldValue(TextFieldType.ADDRESS_STATE)) {
            // let messsage = `The fields were not processed correctly : ${result.text.getFieldValue(TextFieldType.SURNAME) ? '' : ' SURNAME , '} 
            // ${result.text.getFieldValue(TextFieldType.FIRST_NAME) ? '' : ' FIRST_NAME , '} 
            // ${result.text.getFieldValue(TextFieldType.ADDRESS) ? '' : ' ADDRESS , '} 
            // ${result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) ? '' : '  DATE_OF_BIRTH , '} 
            // ${result.text.getFieldValue(TextFieldType.ADDRESS_CITY) ? '' : ' ADDRESS_CITY , '} 
            // ${result.text.getFieldValue(TextFieldType.ADDRESS_STATE) ? '' : ' ADDRESS_STATE , '} `
            let messsage = 'Unable to capture. Please make sure there is good illumination and no glare on the image (code: 02)';
            this.countError = 1
            this.dialogMessage = messsage;
            this.dialogError = true;
            this.buttonDisabled = false;
            this.dialog = false;
            this.loading = false;
            return
          }

          if (result.text) {
            // console.log("DOCUMENT_NUMBER : ",result.text.getFieldValue(TextFieldType.DOCUMENT_NUMBER))
            // console.log("SURNAME : ",result.text.getFieldValue(TextFieldType.SURNAME))
            // console.log("FIRST_NAME : ",result.text.getFieldValue(TextFieldType.FIRST_NAME))
            // console.log("MIDDLE_NAME : ",result.text.getFieldValue(TextFieldType.MIDDLE_NAME))
            // console.log("SURNAME_AND_GIVEN_NAMES : ",result.text.getFieldValue(TextFieldType.SURNAME_AND_GIVEN_NAMES))
            // console.log("DATE_OF_BIRTH : ",result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH))
            // console.log("ADDRESS: ",result.text.getFieldValue(TextFieldType.ADDRESS))
            // console.log("ADDRESS_POSTAL_CODE : ",result.text.getFieldValue(TextFieldType.ADDRESS_STREET))
            // console.log("ADDRESS_CITY : ",result.text.getFieldValue(TextFieldType.ADDRESS_CITY))
            // console.log("ADDRESS_STATE : ",result.text.getFieldValue(TextFieldType.ADDRESS_STATE))
            // console.log("OTHER : ",result.text.getFieldValue(TextFieldType.OTHER))
            // console.log("ADDRESS_POSTAL_CODE : ",result.text.getFieldValue(TextFieldType.ADDRESS_POSTAL_CODE))
            // console.log("SEX : ",result.text.getFieldValue(TextFieldType.SEX))
            // result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) ? console.log("anio : ", result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH).split('-')[0]): null,

            this.setThreeData({
              nroLicencia: result.text.getFieldValue(TextFieldType.DOCUMENT_NUMBER) ? result.text.getFieldValue(TextFieldType.DOCUMENT_NUMBER) : null,
              name: result.text.getFieldValue(TextFieldType.FIRST_NAME) ? result.text.getFieldValue(TextFieldType.FIRST_NAME) : null,
              lastname: result.text.getFieldValue(TextFieldType.SURNAME) ? result.text.getFieldValue(TextFieldType.SURNAME) : null,
              direccion: result.text.getFieldValue(TextFieldType.ADDRESS_STREET) ? result.text.getFieldValue(TextFieldType.ADDRESS_STREET) : null,
              dobDay: result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) ? result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH).split('-')[2] : null,
              dobMonth: result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) ? result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH).split('-')[1] : null,
              dobYear: result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH) ? parseInt(result.text.getFieldValue(TextFieldType.DATE_OF_BIRTH).split('-')[0]) : null,
              cityHome: result.text.getFieldValue(TextFieldType.ADDRESS_CITY) ? result.text.getFieldValue(TextFieldType.ADDRESS_CITY) : null,
              stateHome: result.text.getFieldValue(TextFieldType.ADDRESS_STATE) ? result.text.getFieldValue(TextFieldType.ADDRESS_STATE) : null,
              postalHome: result.text.getFieldValue(TextFieldType.ADDRESS_POSTAL_CODE) ? result.text.getFieldValue(TextFieldType.ADDRESS_POSTAL_CODE) : null,
              genderHome: result.text.getFieldValue(TextFieldType.SEX) ? result.text.getFieldValue(TextFieldType.SEX) : null,

            });

          }




          this.buttonDisabled = false;
          this.completeScan = true;
          this.loading = false;

          return;
        }

      }

      if (data.detail?.action === 'CLOSE' || data.detail?.action === 'CAMERA_PROCESS_CLOSED') {
        this.statusProcess = 'CAMERA_PROCESS_CLOSED'
        this.isOpen = false;
        this.buttonDisabled = false;
        this.dialog = false;
        return
      }

    },
    submit() {
      this.count++;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.state && (this.state.iso_code == "FL" || this.state.iso_code == "CA")) {

          if (this.state.iso_code == "FL") {
            this.selectState = false;
            this.setStateSelected(this.state);
            this.isNationwide = false;

          } else {
            location.href = process.env.VUE_APP_URL_CALIFORNIA;

          }
        } else {
          // this.isNationwide = true;
          location.href = process.env.VUE_APP_URL_OTHER_STATES;
        }
      }
    },

    getLabel(item) {
      if (item) {
        return item.name
      }

    },
    itemClicked(item) {
      this.states = this.statesInit;
    },
    updateItems(text) {
      this.count++;
      if (text != '' && text != undefined) {
        this.states = this.statesInit.filter(item => {
          return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
        })
      } else {
        this.states = this.statesInit;
      }
    },
    goToStep(n) {
      this.completeScan = true;
      this.scanShow = false;
      this.step = n;
      if (n == 4) {
        this.entry = !this.entry;
      }
      this.$vuetify.goTo("#top", {
        duration: 300,
        offset: 0,
        easing: "linear",
      });
    },
    getScan() {

      axios
        .get(`v1/configuration/scan`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {

            this.showScan = res.data.enable;
            if (!this.showScan) {
              this.completeScan = true;
              this.isOpen = false;
              this.isMobile = false;
            }

          }
        });
    },
    loadStates() {
      this.states = [];
      axios
        .get(`v1/states`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {

            this.statesInit = res.data;
            this.states = res.data;
          }
        });
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.completeScan = true;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.getScan();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    window.RegulaDocumentSDK = new DocumentReaderService();
    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        scenario: "Barcode",
        doBarcodes:["bct_PDF417"]
      },
    };

    window.RegulaDocumentSDK.imageProcessParam = {
      processParam: {
        scenario: "Barcode",
        doBarcodes:["bct_PDF417"]
      },
    }


    defineComponents()
      .then(() => window.RegulaDocumentSDK.initialize({ license: 'AAEAAJV98SK8I4J6IQSo5ftHH26mP0Z8MUMoowKutemqnp96z3EtKnPtgR5rRu9gnAEW9RS1qKodSbAcHnZWQHIKFghPTs1TS48eSnVgehZdePLB6YtgHZGo7iJuT1LFLcr09iT+JwGSfBdphLGSsYjRxprRTVRns8QRUpeCehKH29mix5uPQM+MOA04SVi0sMjGQ6p6o0IX8P9YAxf4Afnfv92B6mVLsPBrOH7q8BBpcAJ3TYsGA0qBC/fOYVONVkMYfA/IYuVjWqPoGLPestZkx2u5d93r4SfeA1hZh+/4guJhkfLpYVShpo11r4tTv87Py7wxOh/9la21W0iR7AnoTvzkAAAAAAAAEOit2z9AlaU5QyCL/aWlWLXRN48nB5jYVgjhzNzhMa3zxPNKeNdpZkfmOvERMsxPv6tKaB6g7GjGamHj8m9NVeEEJt1koGji9QIQwYKjRiu7HLJxYT9lcS4iRe1bYoc9SqtLfYSr9twgeM9TILTKVpaD0CTLFX4kzILKCKR7aiGaBeVpjZJDL2G91kCiH45zXvKGh5OdZpDNPNdAijUv1kpXfFce/BLrpKd/MXaI9hbMQ0LolM0F9E8wKbGlSo7x4rS138/SN8IhBlNvCy2V7eo7BVtZJJ0fdrLqCQJVZZbH' }))

    if (isMobileOnly) {
      this.scanShow = true;
    }
    this.loadStates();

    if (this.$route.query.state) {
      this.selectState = false;
      if (this.$route.query.state.toString().toUpperCase() != "FL") {
        // console.log("entro ")
        this.isNationwide = true;
      } else {
        this.isNationwide = false;
      }
    } else {
      this.selectState = true;
    }
  },
  computed: {
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State issued can't be empty.");
      return errors;
    },
  },
  // watch: {
  //   state(val) {
  //     if (val) {
  //       if (val.iso_code == "FL") {
  //         this.isNationwide = false;
  //       } else {
  //         this.isNationwide = true;
  //       }
  //     }
  //   },
  // },
};
</script>



<style scope>
.container-reader {
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.container-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  opacity: 0.9;
  z-index: 100;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container-dialog-scanner .theme--light.v-card {
  background-color: #000 !important;
}

.container-dialog-scanner {
  background-color: #000 !important;
}

.container-scaner-v1 {
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.container-scaner {
  position: relative;
  margin: 0px !important;

  width: 100%;

}

.container-scaner .step {
  padding: 0px 50px
}

.container-scaner .step__title {

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -.5px;
  margin-bottom: 50px;
  color: #fff;
}

.container-skip-scan {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.container-skip-scan a {
  color: #ffffff !important;
  border-bottom: 1px solid;
  font-size: 0.9rem;
}

.overlay-camera-1>img {

  width: 100%;

}

.form-text-yellow {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
  color: #FFE500;
  margin-left: 5px;
  margin: 20px;
}
</style>