import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=85fe8342&class=home%20page-template-default%20page%20page-id-188%20wp-custom-logo%20mega-menu-menu-1%20elementor-default%20elementor-kit-14%20e--ua-blink%20e--ua-chrome%20e--ua-mac%20e--ua-webkit&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports