<template>
  <div data-elementor-type="header" data-elementor-id="134" class="elementor elementor-134 elementor-location-header" id="top">
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-4d1f1fa6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="4d1f1fa6" data-element_type="section"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-539ec448"
          data-id="539ec448" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-5c882732 elementor-widget elementor-widget-text-editor"
              data-id="5c882732" data-element_type="widget" data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <!--span style="color: #ffe500;">Florida</span--> Traffic Ticket Attorney
              </div>
            </div>
          </div>
        </div>
        <div
          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3186237b elementor-hidden-mobile"
          data-id="3186237b" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-566da262 elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon"
              data-id="566da262" data-element_type="widget" data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-phone-alt"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-2077e3f5 elementor-widget__width-initial linki2 elementor-widget elementor-widget-text-editor"
              data-id="2077e3f5" data-element_type="widget" data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <a href="tel:1-800-248-2846">1-800-<span style="color: #ffe500;">CITATION</span></a>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-cf2d4f6 linki2 elementor-widget elementor-widget-text-editor"
              data-id="cf2d4f6" data-element_type="widget" data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                (248-2846) </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-88f7610 elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-sticky"
      data-id="88f7610" data-element_type="section"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;animation&quot;:&quot;none&quot;,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0}">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-76da7e1a"
          data-id="76da7e1a" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-f6a0e95 elementor-widget__width-initial elementor-absolute logomobile e-transform e-transform elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
              data-id="f6a0e95" data-element_type="widget"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_translateY_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.03,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="theme-site-logo.default">
              <div class="elementor-widget-container">
                <a :href="webURL">
                  <img width="350" height="347" src="@/assets/images/logo.png"
                    class="attachment-full size-full wp-image-8" alt="" loading="lazy"
                    srcset="@/assets/images/logo.png 350w, @/assets/images/logo-300x297.png 300w, @/assets/images/logo-150x150.png 150w"
                    sizes="(max-width: 350px) 100vw, 350px"> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-68223f4e"
          data-id="68223f4e" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-543e7678 elementor-nav-menu__align-justify elementor-nav-menu--stretch elementor-widget-mobile__width-auto menumobile elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
              data-id="543e7678" data-element_type="widget"
              data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i class=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}"
              data-widget_type="nav-menu.default">
              <div class="elementor-widget-container">
                <nav
                  class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                  <ul id="menu-1-543e7678" class="elementor-nav-menu" data-smartmenus-id="167870767722078">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" class="elementor-item has-submenu"
                        id="sm-167870767722078-1" aria-haspopup="true" aria-controls="sm-167870767722078-2"
                        aria-expanded="false">About Us<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-167870767722078-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-167870767722078-1" aria-expanded="false">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`"
                            class="elementor-sub-item has-submenu" id="sm-167870767722078-3" aria-haspopup="true"
                            aria-controls="sm-167870767722078-4" aria-expanded="false">We Fight<span
                              class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-167870767722078-4" role="group"
                            aria-hidden="true" aria-labelledby="sm-167870767722078-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`"
                                class="elementor-sub-item">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`"
                                class="elementor-sub-item">Accidents</a>
                            </li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item">All
                                other
                                traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`"
                                class="elementor-sub-item">For
                                Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item">About our firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item">For
                            Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" class="elementor-item has-submenu"
                        id="sm-167870767722078-5" aria-haspopup="true" aria-controls="sm-167870767722078-6"
                        aria-expanded="false">Locations<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-167870767722078-6" role="group"
                        aria-hidden="true" aria-labelledby="sm-167870767722078-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item">Boynton
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item">Daytona
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item">Doral</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item">East
                            Orlando</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item">Florida
                            Keys</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item">Fort
                            Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item">Fort Myers</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item">Fort
                            Pierce</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`"
                            class="elementor-sub-item">Gainesville</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`"
                            class="elementor-sub-item">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item">Hollywood</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item">Kissimmee</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`"
                            class="elementor-sub-item">Miami</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item">Miami
                            Lakes</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item">North
                            Palm
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item">Oakland
                            Park</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item">Port St
                            Lucie</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`"
                            class="elementor-sub-item">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`"
                            class="elementor-sub-item">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`"
                            class="elementor-sub-item">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item">West Miami</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item">West Palm
                            Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item">For lawyers</a></li>
                  </ul>
                </nav>
                <div class="elementor-menu-toggle" @click="clickMenu()" role="button" tabindex="0"
                  aria-label="Menu Toggle" aria-expanded="false" :class="{'elementor-active': menuVisible}">
                  <i role="presentation" class="elementor-menu-toggle__icon--open eicon-menu-bar"
                    v-if="!menuVisible"></i>

                  <i role="presentation" class="elementor-menu-toggle__icon--close eicon-close" v-if="menuVisible"></i>
                  <span class="elementor-screen-only">Menu</span>
                </div>
                <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true"
                  style="top: 45px; width: 390px; left: -290px;" :class="{'menu-active' : menuVisible}">
                  <ul id="menu-2-543e7678" class="elementor-nav-menu" data-smartmenus-id="16787076772220505">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor" tabindex="-1">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" @click.prevent="clickMenuAbout"
                        class="elementor-item has-submenu" tabindex="-1" id="sm-16787076772220505-1"
                        aria-haspopup="true" aria-controls="sm-16787076772220505-2" aria-expanded="false">About Us
                        <span class="sub-arrow"><i class="fas fa-caret-down"></i></span>
                      </a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16787076772220505-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-16787076772220505-1" aria-expanded="false" :class="{'submenu-active': menuAbout}">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`" @click.prevent="clickMenuWeFight"
                            class="elementor-sub-item has-submenu" tabindex="-1" id="sm-16787076772220505-3"
                            aria-haspopup="true" aria-controls="sm-16787076772220505-4" aria-expanded="false">We
                            Fight<span class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16787076772220505-4" :class="{'submenu-active': menuWeFight}" role="group"
                            aria-hidden="true" aria-labelledby="sm-16787076772220505-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item" tabindex="-1">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item" tabindex="-1">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item" tabindex="-1">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`" class="elementor-sub-item"
                                tabindex="-1">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item"
                                tabindex="-1">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`" class="elementor-sub-item"
                                tabindex="-1">Accidents</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item"
                                tabindex="-1">All other traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                                tabindex="-1">For Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item" tabindex="-1">About our
                            firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                            tabindex="-1">For Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" @click.prevent="clickMenuLocations"
                        class="elementor-item has-submenu" tabindex="-1" id="sm-16787076772220505-5"
                        aria-haspopup="true" aria-controls="sm-16787076772220505-6" aria-expanded="false">Locations
                        <span class="sub-arrow"><i class="fas fa-caret-down"></i></span>
                      </a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16787076772220505-6" role="group" :class="{'submenu-active': menuLocations}"
                        aria-hidden="true" aria-labelledby="sm-16787076772220505-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item"
                            tabindex="-1">Boynton Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item" tabindex="-1">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item"
                            tabindex="-1">Daytona Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item"
                            tabindex="-1">Doral</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item"
                            tabindex="-1">East Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item"
                            tabindex="-1">Florida Keys</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item"
                            tabindex="-1">Fort Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item"
                            tabindex="-1">Fort
                            Myers</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item"
                            tabindex="-1">Fort Pierce</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`" class="elementor-sub-item"
                            tabindex="-1">Gainesville</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item"
                            tabindex="-1">Hollywood</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item"
                            tabindex="-1">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item"
                            tabindex="-1">Kissimmee</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`" class="elementor-sub-item"
                            tabindex="-1">Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item"
                            tabindex="-1">Miami Lakes</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">North Palm Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item"
                            tabindex="-1">Oakland Park</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item"
                            tabindex="-1">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item"
                            tabindex="-1">Port
                            St Lucie</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`" class="elementor-sub-item"
                            tabindex="-1">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item"
                            tabindex="-1">West
                            Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">West Palm Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item" tabindex="-1">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item" tabindex="-1">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item" tabindex="-1">For lawyers</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-120d5c3d elementor-widget__width-initial elementor-widget-mobile__width-auto elementor-view-default elementor-widget elementor-widget-icon"
              data-id="120d5c3d" data-element_type="widget" data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <a class="elementor-icon" href="tel:1-800-248-2846" target="_blank">
                    <i aria-hidden="true" class="fas fa-phone-alt"></i> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-1b3a26f9 elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-sticky"
      data-id="1b3a26f9" data-element_type="section"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;animation&quot;:&quot;none&quot;,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0}"
      style="">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3a763b6f"
          data-id="3a763b6f" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-15a6570a elementor-widget__width-initial elementor-absolute logo e-transform e-transform elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
              data-id="15a6570a" data-element_type="widget"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_translateY_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.03,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="theme-site-logo.default">
              <div class="elementor-widget-container">
                <a :href="`${webURL}`">
                  <img width="350" height="347" src="@/assets/images/logo.png"
                    class="attachment-full size-full wp-image-8" alt="" loading="lazy"
                    srcset="@/assets/images/logo.png 350w, @/assets/images/logo-300x297.png 300w, @/assets/images/logo-150x150.png 150w"
                    sizes="(max-width: 350px) 100vw, 350px"> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-733bc85d"
          data-id="733bc85d" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-7e2190ef elementor-widget__width-auto elementor-widget elementor-widget-shortcode"
              data-id="7e2190ef" data-element_type="widget" data-widget_type="shortcode.default">
              <div class="elementor-widget-container">
                <div class="elementor-shortcode">
                  <div id="mega-menu-wrap-menu-1" class="mega-menu-wrap">
                    <div class="mega-menu-toggle">
                      <div class="mega-toggle-blocks-left"></div>
                      <div class="mega-toggle-blocks-center"></div>
                      <div class="mega-toggle-blocks-right">
                        <div class="mega-toggle-block mega-menu-toggle-animated-block mega-toggle-block-0"
                          id="mega-toggle-block-0"><button aria-label="Toggle Menu"
                            class="mega-toggle-animated mega-toggle-animated-slider" type="button"
                            aria-expanded="false">
                            <span class="mega-toggle-animated-box">
                              <span class="mega-toggle-animated-inner"></span>
                            </span>
                          </button></div>
                      </div>
                    </div>
                    <ul id="mega-menu-menu-1" class="mega-menu max-mega-menu mega-menu-horizontal"
                      data-event="hover_intent" data-effect="fade_up" data-effect-speed="200"
                      data-effect-mobile="disabled" data-effect-speed-mobile="0" data-mobile-force-width="false"
                      data-second-click="go" data-document-click="collapse" data-vertical-behaviour="standard"
                      data-breakpoint="768" data-unbind="true" data-mobile-state="collapse_all"
                      data-hover-intent-timeout="300" data-hover-intent-interval="100">
                      <li
                        class="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-current-menu-item mega-current_page_item mega-menu-item-home mega-align-bottom-left mega-menu-flyout mega-menu-item-4432"
                        id="mega-menu-item-4432"><a class="mega-menu-link" :href="`${webURL}/#how`"
                          aria-current="page" tabindex="0">HOW IT WORKS</a></li>
                      <li
                        class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-has-children mega-align-bottom-left mega-menu-flyout mega-menu-item-4431"
                        id="mega-menu-item-4431"><a class="mega-menu-link" :href="`${webURL}/about-us/`"
                          aria-haspopup="true" aria-expanded="false" tabindex="0">About Us<span class="mega-indicator"
                            tabindex="0" role="button" aria-label="About Us submenu"
                            data-has-click-event="true"></span></a>
                        <ul class="mega-sub-menu">
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-has-children mega-menu-item-4428"
                            id="mega-menu-item-4428"><a class="mega-menu-link"
                              :href="`${webURL}/we-fight-traffic-tickets/`" aria-haspopup="true"
                              aria-expanded="false">We Fight<span class="mega-indicator" tabindex="0" role="button"
                                aria-label="We Fight submenu" data-has-click-event="true"></span></a>
                            <ul class="mega-sub-menu">
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4429"
                                id="mega-menu-item-4429"><a class="mega-menu-link"
                                  :href="`${webURL}/services/speeding-ticket-lawyer-2/`">Speeding Tickets</a>
                              </li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4426"
                                id="mega-menu-item-4426"><a class="mega-menu-link"
                                  :href="`${webURL}/services/reckless-careless-driving/`">Reckless / Careless
                                  Driving</a></li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4427"
                                id="mega-menu-item-4427"><a class="mega-menu-link"
                                  :href="`${webURL}/services/lawyer-for-red-light-tickets/`">Red Light
                                  Tickets</a></li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4424"
                                id="mega-menu-item-4424"><a class="mega-menu-link"
                                  :href="`${webURL}/services/suspended-license/`">Suspended License</a></li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4423"
                                id="mega-menu-item-4423"><a class="mega-menu-link"
                                  :href="`${webURL}/services/dui/`">DUI’s</a></li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4425"
                                id="mega-menu-item-4425"><a class="mega-menu-link"
                                  :href="`${webURL}/services/accidents/`">Accidents</a></li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4762"
                                id="mega-menu-item-4762"><a class="mega-menu-link"
                                  :href="`${webURL}/we-fight-traffic-tickets/`">All other traffic tickets</a>
                              </li>
                              <li
                                class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4761"
                                id="mega-menu-item-4761"><a class="mega-menu-link"
                                  :href="`${webURL}/services/professional-drivers/`">For Professional
                                  Drivers</a>
                              </li>
                            </ul>
                          </li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4436"
                            id="mega-menu-item-4436"><a class="mega-menu-link"
                              :href="`${webURL}/about-us/`">About our firm</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-4422"
                            id="mega-menu-item-4422"><a class="mega-menu-link"
                              :href="`${webURL}/services/professional-drivers/`">For Professional Drivers</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-megamenu mega-menu-item-4678"
                        id="mega-menu-item-4678"><a class="mega-menu-link"
                          :href="`${webURL}/ticket-clinic-locations/`" aria-haspopup="true"
                          aria-expanded="false" tabindex="0">Locations<span class="mega-indicator" tabindex="0"
                            role="button" aria-label="Locations submenu" data-has-click-event="true"></span></a>
                        <ul class="mega-sub-menu">
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4694"
                            id="mega-menu-item-4694"><a class="mega-menu-link"
                              :href="`${webURL}/locations/boynton-beach/`">Boynton Beach</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-5376"
                            id="mega-menu-item-5376"><a class="mega-menu-link"
                              :href="`${webURL}/locations/clearwater-traffic-lawyers/`">Clearwater</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4695"
                            id="mega-menu-item-4695"><a class="mega-menu-link"
                              :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`">Coral
                              Springs</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4696"
                            id="mega-menu-item-4696"><a class="mega-menu-link"
                              :href="`${webURL}/locations/daytona-beach/`">Daytona Beach</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4697"
                            id="mega-menu-item-4697"><a class="mega-menu-link"
                              :href="`${webURL}/locations/doral/`">Doral</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4698"
                            id="mega-menu-item-4698"><a class="mega-menu-link"
                              :href="`${webURL}/locations/east-orlando/`">East Orlando</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4699"
                            id="mega-menu-item-4699"><a class="mega-menu-link"
                              :href="`${webURL}/locations/florida-keys/`">Florida Keys</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4700"
                            id="mega-menu-item-4700"><a class="mega-menu-link"
                              :href="`${webURL}/locations/fort-lauderdale/`">Fort Lauderdale</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4701"
                            id="mega-menu-item-4701"><a class="mega-menu-link"
                              :href="`${webURL}/locations/fort-myers/`">Fort Myers</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4693"
                            id="mega-menu-item-4693"><a class="mega-menu-link"
                              :href="`${webURL}/locations/fort-pierce/`">Fort Pierce</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4702"
                            id="mega-menu-item-4702"><a class="mega-menu-link"
                              :href="`${webURL}/locations/gainesville/`">Gainesville</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4703"
                            id="mega-menu-item-4703"><a class="mega-menu-link"
                              :href="`${webURL}/locations/hialeah-traffic-lawyer/`">Hialeah</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4692"
                            id="mega-menu-item-4692"><a class="mega-menu-link"
                              :href="`${webURL}/locations/hollywood/`">Hollywood</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4691"
                            id="mega-menu-item-4691"><a class="mega-menu-link"
                              :href="`${webURL}/locations/kendall/`">Kendall</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4690"
                            id="mega-menu-item-4690"><a class="mega-menu-link"
                              :href="`${webURL}/locations/kissimmee/`">Kissimmee</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4704"
                            id="mega-menu-item-4704"><a class="mega-menu-link"
                              :href="`${webURL}/locations/ticket-lawyer-miami/`">Miami</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4689"
                            id="mega-menu-item-4689"><a class="mega-menu-link"
                              :href="`${webURL}/locations/miami-lakes/`">Miami Lakes</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4688"
                            id="mega-menu-item-4688"><a class="mega-menu-link"
                              :href="`${webURL}/locations/north-palm-beach/`">North Palm Beach</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4687"
                            id="mega-menu-item-4687"><a class="mega-menu-link"
                              :href="`${webURL}/locations/oakland-park/`">Oakland Park</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4686"
                            id="mega-menu-item-4686"><a class="mega-menu-link"
                              :href="`${webURL}/locations/orange/`">Orlando</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4705"
                            id="mega-menu-item-4705"><a class="mega-menu-link"
                              :href="`${webURL}/locations/panama-city-traffic-lawyer/`">Panama City</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4685"
                            id="mega-menu-item-4685"><a class="mega-menu-link"
                              :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`">Pembroke Pines</a>
                          </li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4684"
                            id="mega-menu-item-4684"><a class="mega-menu-link"
                              :href="`${webURL}/locations/st-lucie/`">Port St Lucie</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4683"
                            id="mega-menu-item-4683"><a class="mega-menu-link"
                              :href="`${webURL}/locations/south-dade-ticket-lawyer/`">South Dade</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4682"
                            id="mega-menu-item-4682"><a class="mega-menu-link"
                              :href="`${webURL}/locations/tamiami-ticket-lawyer/`">Tamiami</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4681"
                            id="mega-menu-item-4681"><a class="mega-menu-link"
                              :href="`${webURL}/locations/tampa-traffic-lawyers/`">Tampa</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-item-4680"
                            id="mega-menu-item-4680"><a class="mega-menu-link"
                              :href="`${webURL}/locations/westmiami/`">West Miami</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3 mega-menu-clear mega-menu-item-4679"
                            id="mega-menu-item-4679"><a class="mega-menu-link"
                              :href="`${webURL}/locations/west-palm-beach/`">West Palm Beach</a></li>
                          <li
                            class="mega-menu-item mega-menu-item-type-widget widget_custom_html mega-menu-columns-3-of-3 mega-menu-clear mega-menu-item-custom_html-2"
                            id="mega-menu-item-custom_html-2">
                            <div class="textwidget custom-html-widget">
                              <hr style="margin-top:15px; margin-bottom:20px">
                              <ul class="mega-sub-menu" style="margin-left:10px">
                                <li
                                  class="mega-menu-item2 mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3">
                                  <a class="mega-menu-link" href="https://www.ticketclinic.com/">Ticket in
                                    California?</a>
                                </li>
                                <li
                                  class="mega-menu-item2 mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3">
                                  <a class="mega-menu-link" href="https://www.ticketclinicgeorgia.com/">Ticket in
                                    Georgia?</a>
                                </li>
                                <li
                                  class="mega-menu-item2 mega-menu-item-type-post_type mega-menu-item-object-locations mega-menu-columns-1-of-3">
                                  <a class="mega-menu-link"
                                    href="https://www.theticketclinic.com/ticket-clinic-locations/tickets-in-another-state">Nationwide
                                    Assistance</a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-4734"
                        id="mega-menu-item-4734"><a class="mega-menu-link" :href="`${domain}`"
                          tabindex="0">HIRE US NOW</a></li>
                      <li
                        class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-4430"
                        id="mega-menu-item-4430"><a class="mega-menu-link" :href="`${webURL}/blogs/`"
                          tabindex="0">Blog</a></li>
                      <li
                        class="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-4817"
                        id="mega-menu-item-4817"><a class="mega-menu-link" :href="`${webURL}/for-lawyers/`"
                          tabindex="0">For lawyers</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-3576004 elementor-widget__width-auto elementor-widget elementor-widget-button"
              data-id="3576004" data-element_type="widget" data-widget_type="button.default">
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <a href="https://login.theticketclinic.com/" target="_blank"
                    class="elementor-button-link elementor-button elementor-size-md" role="button">
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-text">Sign In</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="logo-menu-desktop"
      class="elementor-section elementor-top-section elementor-element elementor-element-88f7610 elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-sticky elementor-sticky--effects elementor-sticky--active elementor-section--handles-inside"
      data-id="88f7610" data-element_type="section"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;animation&quot;:&quot;none&quot;,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0}"
      style="position: fixed; margin-top: 0px; margin-bottom: 0px; top: 0px;">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-76da7e1a"
          data-id="76da7e1a" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-f6a0e95 elementor-widget__width-initial elementor-absolute logomobile e-transform e-transform elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
              data-id="f6a0e95" data-element_type="widget"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_translateY_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.03,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="theme-site-logo.default">
              <div class="elementor-widget-container">
                <a :href="`${webURL}`">
                  <img width="350" height="347" src="@/assets/images/logo.png"
                    class="attachment-full size-full wp-image-8" alt="" loading="lazy"
                    srcset="@/assets/images/logo.png 350w, @/assets/images/logo-300x297.png 300w, @/assets/images/logo-150x150.png 150w"
                    sizes="(max-width: 350px) 100vw, 350px"> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-68223f4e"
          data-id="68223f4e" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-543e7678 elementor-nav-menu__align-justify elementor-nav-menu--stretch elementor-widget-mobile__width-auto menumobile elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
              data-id="543e7678" data-element_type="widget"
              data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i class=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}"
              data-widget_type="nav-menu.default">
              <div class="elementor-widget-container">
                <nav
                  class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                  <ul id="menu-1-543e7678" class="elementor-nav-menu" data-smartmenus-id="16782753460021846">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" class="elementor-item has-submenu"
                        id="sm-16782753460021846-1" aria-haspopup="true" aria-controls="sm-16782753460021846-2"
                        aria-expanded="false">About Us<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-16782753460021846-1" aria-expanded="false">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`"
                            class="elementor-sub-item has-submenu" id="sm-16782753460021846-3" aria-haspopup="true"
                            aria-controls="sm-16782753460021846-4" aria-expanded="false">We Fight<span
                              class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-4" role="group"
                            aria-hidden="true" aria-labelledby="sm-16782753460021846-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`"
                                class="elementor-sub-item">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`"
                                class="elementor-sub-item">Accidents</a>
                            </li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item">All
                                other traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`"
                                class="elementor-sub-item">For
                                Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item">About our firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item">For
                            Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" class="elementor-item has-submenu"
                        id="sm-16782753460021846-5" aria-haspopup="true" aria-controls="sm-16782753460021846-6"
                        aria-expanded="false">Locations<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-6" role="group"
                        aria-hidden="true" aria-labelledby="sm-16782753460021846-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item">Boynton
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item">Daytona
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item">Doral</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item">East
                            Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item">Florida
                            Keys</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item">Fort
                            Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item">Fort Myers</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item">Fort
                            Pierce</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`"
                            class="elementor-sub-item">Gainesville</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`"
                            class="elementor-sub-item">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item">Hollywood</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item">Kissimmee</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`"
                            class="elementor-sub-item">Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item">Miami
                            Lakes</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item">North
                            Palm
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item">Oakland
                            Park</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item">Port St
                            Lucie</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`"
                            class="elementor-sub-item">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`"
                            class="elementor-sub-item">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`"
                            class="elementor-sub-item">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item">West Miami</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item">West Palm
                            Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item">For lawyers</a></li>
                  </ul>
                </nav>
                <div class="elementor-menu-toggle" role="button" tabindex="0" aria-label="Menu Toggle"
                  aria-expanded="false" style="">
                  <i aria-hidden="true" role="presentation"
                    class="elementor-menu-toggle__icon--open eicon-menu-bar"></i><i aria-hidden="true"
                    role="presentation" class="elementor-menu-toggle__icon--close eicon-close"></i> <span
                    class="elementor-screen-only">Menu</span>
                </div>
                <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true"
                  style="top: 0px; --menu-height:0; width: 1425px; left: 0px;">
                  <ul id="menu-2-543e7678" class="elementor-nav-menu" data-smartmenus-id="1678275346005835">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor" tabindex="-1">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" class="elementor-item has-submenu" tabindex="-1"
                        id="sm-1678275346005835-1" aria-haspopup="true" aria-controls="sm-1678275346005835-2"
                        aria-expanded="false">About Us<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-1678275346005835-1" aria-expanded="false">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`"
                            class="elementor-sub-item has-submenu" tabindex="-1" id="sm-1678275346005835-3"
                            aria-haspopup="true" aria-controls="sm-1678275346005835-4" aria-expanded="false">We
                            Fight<span class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-4" role="group"
                            aria-hidden="true" aria-labelledby="sm-1678275346005835-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item" tabindex="-1">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item" tabindex="-1">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item" tabindex="-1">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`" class="elementor-sub-item"
                                tabindex="-1">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item"
                                tabindex="-1">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`" class="elementor-sub-item"
                                tabindex="-1">Accidents</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item"
                                tabindex="-1">All other traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                                tabindex="-1">For Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item" tabindex="-1">About our
                            firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                            tabindex="-1">For Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" class="elementor-item has-submenu"
                        tabindex="-1" id="sm-1678275346005835-5" aria-haspopup="true"
                        aria-controls="sm-1678275346005835-6" aria-expanded="false">Locations<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-6" role="group"
                        aria-hidden="true" aria-labelledby="sm-1678275346005835-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item"
                            tabindex="-1">Boynton Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item" tabindex="-1">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item"
                            tabindex="-1">Daytona Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item"
                            tabindex="-1">Doral</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item"
                            tabindex="-1">East Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item"
                            tabindex="-1">Florida Keys</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item"
                            tabindex="-1">Fort Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item"
                            tabindex="-1">Fort Myers</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item"
                            tabindex="-1">Fort Pierce</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`" class="elementor-sub-item"
                            tabindex="-1">Gainesville</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item"
                            tabindex="-1">Hollywood</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item"
                            tabindex="-1">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item"
                            tabindex="-1">Kissimmee</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`" class="elementor-sub-item"
                            tabindex="-1">Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item"
                            tabindex="-1">Miami Lakes</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">North Palm Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item"
                            tabindex="-1">Oakland Park</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item"
                            tabindex="-1">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item"
                            tabindex="-1">Port
                            St Lucie</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`" class="elementor-sub-item"
                            tabindex="-1">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item"
                            tabindex="-1">West Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">West Palm Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item" tabindex="-1">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item" tabindex="-1">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item" tabindex="-1">For lawyers</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-120d5c3d elementor-widget__width-initial elementor-widget-mobile__width-auto elementor-view-default elementor-widget elementor-widget-icon"
              data-id="120d5c3d" data-element_type="widget" data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <a class="elementor-icon" href="tel:1-800-248-2846" target="_blank">
                    <i aria-hidden="true" class="fas fa-phone-alt"></i> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-88f7610 elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-sticky elementor-sticky--effects elementor-sticky__spacer"
      data-id="88f7610" data-element_type="section"
      data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;animation&quot;:&quot;none&quot;,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0}"
      style="visibility: hidden; transition: none 0s ease 0s; animation: 0s ease 0s 1 normal none running none;">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-76da7e1a"
          data-id="76da7e1a" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-f6a0e95 elementor-widget__width-initial elementor-absolute logomobile e-transform e-transform elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
              data-id="f6a0e95" data-element_type="widget"
              data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_translateY_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.03,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_scale_effect_hover_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
              data-widget_type="theme-site-logo.default">
              <div class="elementor-widget-container">
                <a :href="`${webURL}`">
                  <img width="350" height="347" src="@/assets/images/logo.png"
                    class="attachment-full size-full wp-image-8" alt="" loading="lazy"
                    srcset="@/assets/images/logo.png 350w, @/assets/images/logo-300x297.png 300w, @/assets/images/logo-150x150.png 150w"
                    sizes="(max-width: 350px) 100vw, 350px"> </a>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-68223f4e"
          data-id="68223f4e" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-543e7678 elementor-nav-menu__align-justify elementor-nav-menu--stretch elementor-widget-mobile__width-auto menumobile elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
              data-id="543e7678" data-element_type="widget"
              data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i class=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}"
              data-widget_type="nav-menu.default">
              <div class="elementor-widget-container">
                <nav
                  class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                  <ul id="menu-1-543e7678" class="elementor-nav-menu" data-smartmenus-id="16782753460021846">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" class="elementor-item has-submenu"
                        id="sm-16782753460021846-1" aria-haspopup="true" aria-controls="sm-16782753460021846-2"
                        aria-expanded="false">About Us<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-16782753460021846-1" aria-expanded="false">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`"
                            class="elementor-sub-item has-submenu" id="sm-16782753460021846-3" aria-haspopup="true"
                            aria-controls="sm-16782753460021846-4" aria-expanded="false">We Fight<span
                              class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-4" role="group"
                            aria-hidden="true" aria-labelledby="sm-16782753460021846-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`"
                                class="elementor-sub-item">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`"
                                class="elementor-sub-item">Accidents</a>
                            </li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item">All
                                other traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`"
                                class="elementor-sub-item">For
                                Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item">About our firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item">For
                            Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" class="elementor-item has-submenu"
                        id="sm-16782753460021846-5" aria-haspopup="true" aria-controls="sm-16782753460021846-6"
                        aria-expanded="false">Locations<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-16782753460021846-6" role="group"
                        aria-hidden="true" aria-labelledby="sm-16782753460021846-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item">Boynton
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item">Daytona
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item">Doral</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item">East
                            Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item">Florida
                            Keys</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item">Fort
                            Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item">Fort Myers</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item">Fort
                            Pierce</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`"
                            class="elementor-sub-item">Gainesville</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`"
                            class="elementor-sub-item">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item">Hollywood</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item">Kissimmee</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`"
                            class="elementor-sub-item">Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item">Miami
                            Lakes</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item">North
                            Palm
                            Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item">Oakland
                            Park</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item">Port St
                            Lucie</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`"
                            class="elementor-sub-item">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`"
                            class="elementor-sub-item">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`"
                            class="elementor-sub-item">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item">West Miami</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item">West Palm
                            Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item">For lawyers</a></li>
                  </ul>
                </nav>
                <div class="elementor-menu-toggle" role="button" tabindex="0" aria-label="Menu Toggle"
                  aria-expanded="false" style="">
                  <i aria-hidden="true" role="presentation"
                    class="elementor-menu-toggle__icon--open eicon-menu-bar"></i><i aria-hidden="true"
                    role="presentation" class="elementor-menu-toggle__icon--close eicon-close"></i> <span
                    class="elementor-screen-only">Menu</span>
                </div>
                <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true"
                  style="top: 0px; --menu-height:0; width: 1425px; left: 0px;">
                  <ul id="menu-2-543e7678" class="elementor-nav-menu" data-smartmenus-id="1678275346005835">
                    <li
                      class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4432">
                      <a :href="`${webURL}/#how`" aria-current="page"
                        class="elementor-item elementor-item-anchor" tabindex="-1">HOW IT WORKS</a>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4431">
                      <a :href="`${webURL}/about-us/`" class="elementor-item has-submenu" tabindex="-1"
                        id="sm-1678275346005835-1" aria-haspopup="true" aria-controls="sm-1678275346005835-2"
                        aria-expanded="false">About Us<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-2" role="group"
                        aria-hidden="true" aria-labelledby="sm-1678275346005835-1" aria-expanded="false">
                        <li
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4428">
                          <a :href="`${webURL}/we-fight-traffic-tickets/`"
                            class="elementor-sub-item has-submenu" tabindex="-1" id="sm-1678275346005835-3"
                            aria-haspopup="true" aria-controls="sm-1678275346005835-4" aria-expanded="false">We
                            Fight<span class="sub-arrow"><i class="fas fa-caret-down"></i></span></a>
                          <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-4" role="group"
                            aria-hidden="true" aria-labelledby="sm-1678275346005835-3" aria-expanded="false">
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4429"><a
                                :href="`${webURL}/services/speeding-ticket-lawyer-2/`"
                                class="elementor-sub-item" tabindex="-1">Speeding Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4426"><a
                                :href="`${webURL}/services/reckless-careless-driving/`"
                                class="elementor-sub-item" tabindex="-1">Reckless / Careless Driving</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4427"><a
                                :href="`${webURL}/services/lawyer-for-red-light-tickets/`"
                                class="elementor-sub-item" tabindex="-1">Red Light Tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4424"><a
                                :href="`${webURL}/services/suspended-license/`" class="elementor-sub-item"
                                tabindex="-1">Suspended License</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4423"><a
                                :href="`${webURL}/services/dui/`" class="elementor-sub-item"
                                tabindex="-1">DUI’s</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4425"><a
                                :href="`${webURL}/services/accidents/`" class="elementor-sub-item"
                                tabindex="-1">Accidents</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4762"><a
                                :href="`${webURL}/we-fight-traffic-tickets/`" class="elementor-sub-item"
                                tabindex="-1">All other traffic tickets</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4761"><a
                                :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                                tabindex="-1">For Professional Drivers</a></li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4436"><a
                            :href="`${webURL}/about-us/`" class="elementor-sub-item" tabindex="-1">About our
                            firm</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4422"><a
                            :href="`${webURL}/services/professional-drivers/`" class="elementor-sub-item"
                            tabindex="-1">For Professional Drivers</a></li>
                      </ul>
                    </li>
                    <li
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-4678">
                      <a :href="`${webURL}/ticket-clinic-locations/`" class="elementor-item has-submenu"
                        tabindex="-1" id="sm-1678275346005835-5" aria-haspopup="true"
                        aria-controls="sm-1678275346005835-6" aria-expanded="false">Locations<span class="sub-arrow"><i
                            class="fas fa-caret-down"></i></span></a>
                      <ul class="sub-menu elementor-nav-menu--dropdown" id="sm-1678275346005835-6" role="group"
                        aria-hidden="true" aria-labelledby="sm-1678275346005835-5" aria-expanded="false">
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4694"><a
                            :href="`${webURL}/locations/boynton-beach/`" class="elementor-sub-item"
                            tabindex="-1">Boynton Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-5376"><a
                            :href="`${webURL}/locations/clearwater-traffic-lawyers/`"
                            class="elementor-sub-item" tabindex="-1">Clearwater</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4695"><a
                            :href="`${webURL}/locations/coral-springs-traffic-ticket-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Coral Springs</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4696"><a
                            :href="`${webURL}/locations/daytona-beach/`" class="elementor-sub-item"
                            tabindex="-1">Daytona Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4697"><a
                            :href="`${webURL}/locations/doral/`" class="elementor-sub-item"
                            tabindex="-1">Doral</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4698"><a
                            :href="`${webURL}/locations/east-orlando/`" class="elementor-sub-item"
                            tabindex="-1">East Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4699"><a
                            :href="`${webURL}/locations/florida-keys/`" class="elementor-sub-item"
                            tabindex="-1">Florida Keys</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4700"><a
                            :href="`${webURL}/locations/fort-lauderdale/`" class="elementor-sub-item"
                            tabindex="-1">Fort Lauderdale</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4701"><a
                            :href="`${webURL}/locations/fort-myers/`" class="elementor-sub-item"
                            tabindex="-1">Fort Myers</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4693"><a
                            :href="`${webURL}/locations/fort-pierce/`" class="elementor-sub-item"
                            tabindex="-1">Fort Pierce</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4702"><a
                            :href="`${webURL}/locations/gainesville/`" class="elementor-sub-item"
                            tabindex="-1">Gainesville</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4703"><a
                            :href="`${webURL}/locations/hialeah-traffic-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Hialeah</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4692"><a
                            :href="`${webURL}/locations/hollywood/`" class="elementor-sub-item"
                            tabindex="-1">Hollywood</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4691"><a
                            :href="`${webURL}/locations/kendall/`" class="elementor-sub-item"
                            tabindex="-1">Kendall</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4690"><a
                            :href="`${webURL}/locations/kissimmee/`" class="elementor-sub-item"
                            tabindex="-1">Kissimmee</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4704"><a
                            :href="`${webURL}/locations/ticket-lawyer-miami/`" class="elementor-sub-item"
                            tabindex="-1">Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4689"><a
                            :href="`${webURL}/locations/miami-lakes/`" class="elementor-sub-item"
                            tabindex="-1">Miami Lakes</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4688"><a
                            :href="`${webURL}/locations/north-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">North Palm Beach</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4687"><a
                            :href="`${webURL}/locations/oakland-park/`" class="elementor-sub-item"
                            tabindex="-1">Oakland Park</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4686"><a
                            :href="`${webURL}/locations/orange/`" class="elementor-sub-item"
                            tabindex="-1">Orlando</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4705"><a
                            :href="`${webURL}/locations/panama-city-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Panama City</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4685"><a
                            :href="`${webURL}/locations/pembroke-pines-traffic-lawyer/`"
                            class="elementor-sub-item" tabindex="-1">Pembroke Pines</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4684"><a
                            :href="`${webURL}/locations/st-lucie/`" class="elementor-sub-item"
                            tabindex="-1">Port
                            St Lucie</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4683"><a
                            :href="`${webURL}/locations/south-dade-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">South Dade</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4682"><a
                            :href="`${webURL}/locations/tamiami-ticket-lawyer/`" class="elementor-sub-item"
                            tabindex="-1">Tamiami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4681"><a
                            :href="`${webURL}/locations/tampa-traffic-lawyers/`" class="elementor-sub-item"
                            tabindex="-1">Tampa</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4680"><a
                            :href="`${webURL}/locations/westmiami/`" class="elementor-sub-item"
                            tabindex="-1">West Miami</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-locations menu-item-4679"><a
                            :href="`${webURL}/locations/west-palm-beach/`" class="elementor-sub-item"
                            tabindex="-1">West Palm Beach</a></li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4734"><a
                        :href="`${domain}`" class="elementor-item" tabindex="-1">HIRE US NOW</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4430"><a
                        :href="`${webURL}/blogs/`" class="elementor-item" tabindex="-1">Blog</a></li>
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4817"><a
                        :href="`${webURL}/for-lawyers/`" class="elementor-item" tabindex="-1">For lawyers</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-120d5c3d elementor-widget__width-initial elementor-widget-mobile__width-auto elementor-view-default elementor-widget elementor-widget-icon"
              data-id="120d5c3d" data-element_type="widget" data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <a class="elementor-icon" href="tel:1-800-248-2846" target="_blank">
                    <i aria-hidden="true" class="fas fa-phone-alt"></i> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  // import('../../assets/css/post-134.css');
  // import('../../assets/css/post-14.css');
  // import('../../assets/css/maxmegamenu.css');
  // import('../../assets/css/widget-nav-menu.min.css');
  export default {
    data() {
      return {
        menuVisible: false,
        elementorActive: "elementor-active",
        menuStyle: "menu-active",
        menuAbout: false,
        menuWeFight: false,
        menuLocations: false,
        webURL: process.env.VUE_APP_WEB_URL,
        domain: process.env.VUE_APP_DOMAIN
      }
    },
    created() {
      // has-submenu
      // console.log(this.$refs);
    },
    methods: {
      goHome() {
        window.location.href = process.env.VUE_APP_WEB_URL;
      },
      clickMenu() {
        this.menuVisible = !this.menuVisible;

        if(!this.menuVisible){
          this.menuAbout = false;
          this.menuWeFight = false;
          this.menuLocations = !this.menuLocations;
        }
      },
      preventClick() {

      },
      clickMenuAbout(){
        this.menuAbout = !this.menuAbout;
        
        if(!this.menuAbout){
          this.menuWeFight = false;
        }

        if(this.menuAbout){
          this.menuLocations = false;
        }
      },
      clickMenuWeFight(){
        this.menuWeFight = !this.menuWeFight;
      },
      clickMenuLocations(){
        this.menuLocations = !this.menuLocations;

        if(this.menuLocations){
          this.menuAbout = false;
          this.menuWeFight = false;
        }

      }
    },
  };
</script>