<template lang="html">
    <div class="v-autocomplete" >
        <div class="v-autocomplete-input-group"  >
           <div class="v-autocomplete-input-container" >
             
               <input 
                  :id="inputAttrs.id || inputId"
                  :class="[disabled ? 'disabledInput' : '']"
                  type="text"
                  :value="searchText"
                  class="v-autocomplete-input"
                  :placeholder="inputAttrs.placeholder || placeholder"
                  :disabled="inputAttrs.disabled || disabled"
                  @blur="blur" @focus="focus" @input="inputChange"
                  @keydown.enter.prevent="keyEnter" @keydown.tab="keyEnter" 
                  @keydown.up="keyUp" @keydown.down="keyDown"
                  >
              
              <div class="elementor-icon">
                  <i aria-hidden="true" style="font-size:0.9rem;" class="fa-solid fas" :class="classIcon"></i>
              </div>
            </div>
           
        </div>
        <div class="v-autocomplete-list" v-if="show">
            <div class="v-autocomplete-list-item" :id="'v-autocomplete-id-'+item.id" v-for="item, i in internalItems" @click="onClickItem(item)"
                :class="{'v-autocomplete-item-active': i === cursor}">
                <div :is="componentItem" :item="item" :item-html="highlightText(item)"></div>
            </div>
            <div class="v-autocomplete-not-data" v-if="internalItems.length==0">
              No data available
            </div>
        </div>
    </div>
</template> 

<script setup>
import Item from './ItemNew.vue'
import utils from './utils.js'

export default {
  name: 'vue-customer-autocomplete',
  props: {
    componentItem: { default: () => Item },
    minLen: { type: Number, default: utils.minLen },
    wait: { type: Number, default: utils.wait },
    value: null,
    getLabel: {
      type: Function,
      default: item => item
    },
    items: Array,
    autoSelectOneItem: { type: Boolean, default: false },
    placeholder: String,
    inputClass: {type: String, default: 'v-autocomplete-input'},
    disabled: {type: Boolean, default: false},
    inputAttrs: {type: Object, default: () => {return {}}},
    valueInitSearch : { default: () => '' },
  },
  data () {
    return {
      inputId: "v-autocomplete-id",
      countInit: 0,
      searchText: this.valueInitSearch,
      showList: false,
      cursor: -1,
      internalItems: this.items || [],
      classIcon: "fa-caret-down",
      flagInput : false,
      flagInputText:false,
      flagClick : false
    }
  },
  computed: {
    hasItems () {
      return !!this.internalItems.length
    },
    show () {
      return this.showList 
    }
  },
  methods: {
    highlightText(item) {
      let highlightedText = '';
      let searchIndex = 0;
     
      let textName = item.name
      if(textName){
        for (let i = 0; i < textName.length; i++) {

        if (textName[i].toLowerCase() === this.searchText[searchIndex]?.toLowerCase() && this.flagInput) {
          highlightedText += `<span style="background-color:rgba(40,40,40,0.05);">${textName[i]}</span>`;
          searchIndex++;
        } else {
          highlightedText += textName[i];
        } 
        }
        return highlightedText;
      }
     
    },
    inputChange (event) {
      this.flagInputText = true;
      this.searchText = event.target.value
      this.flagInput = true;
      utils.minLen = this.minLen
      utils.wait = this.wait
      this.showList = true;
      this.cursor = -1;
      this.flagClick = false;
      this.classIcon = "fa-caret-up"
      this.onSelectItem(null, 'inputChange')
      utils.callUpdateItems(this.searchText, this.updateItems)
      this.$emit('change', this.searchText)
    },

    updateItems () {
      this.$emit('update-items', this.searchText)
    },

    focus () {
      
       
      this.flagClick = false;
      this.classIcon = "fa-caret-up"
      this.showList = true
        setTimeout( () => {
          this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
        }, 
        10)
    },

    blur () {
      this.flagClick = false;
      this.flagInputText = false;
      setTimeout( () => {
        this.classIcon = "fa-caret-down"
        this.showList = false
        }, 
        200)
    },

    onClickItem(item) {
      console.log("onClickItem: ",item)
        this.flagInputText = false;
        this.flagClick = true;
   
      this.flagInput = false;
      this.onSelectItem(item) 
      this.$emit('item-clicked', item)
    },

    onSelectItem (item) {
      this.flagClick = false;
      if (item) {
        this.cursor= this.internalItems.findIndex(obj => obj.id === item.id && obj.name === item.name);
        this.searchText = this.getLabel(item)
        this.$emit('item-selected', item)
      } else {
      
        this.setItems(this.items)
      }
      this.$emit('input', item)
    },

    setItems (items) {
      this.internalItems = items || [];

    },

    keyUp (e) {
      if (this.cursor > -1) {
        this.cursor--
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    keyDown (e) {
      if (this.cursor < this.internalItems.length) {
        this.cursor++
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    itemView (item) {
      if (item && item.scrollIntoView) {
        item.scrollIntoView(false)
      }
    },

    keyEnter (e) {
      if (this.showList && this.internalItems[this.cursor]) {
        this.onSelectItem(this.internalItems[this.cursor])
        this.showList = false
        this.classIcon = "fa-caret-down"
      }
    },
    isSelectedValue (value) {

      return 1 == this.internalItems.length && value == this.internalItems[0]
    },
   
  },
  mounted(){

      this.getLabel('')
      if(this.value!=null){
     
            this.cursor= this.internalItems.findIndex(obj => obj.id === this.value.id && obj.name === this.value.name);
            this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
            this.searchText = this.getLabel(this.value)
      }
  },

  updated(){

      this.getLabel('')
      if(this.value!=null){
            this.cursor= this.internalItems.findIndex(obj => obj.id === this.value.id && obj.name === this.value.name);
            this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
            this.searchText = this.getLabel(this.value)
      }
  },
  watch: {
    items (newValue) {
      this.setItems(newValue)
    },
   
  }
}
</script>

<style scope>
.disabledInput{
  
    color: #ccc !important;
    background-color: #f3f3f3 !important;
    border-color: #ccc !important;
    cursor: not-allowed !important;
      
}
 .v-autocomplete .v-autocomplete-input-group .v-autocomplete-input{
      cursor:auto ;
      font-size:1rem;
      padding: 12px 14px;
      width: calc(100% - 32px);
      outline: none;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      width: 100%;
  }
   
   .v-autocomplete-input-container{
      position: relative;
   }


  .v-autocomplete-input-container .elementor-icon {
    position: absolute ;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .v-autocomplete .v-autocomplete-list{
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    position: absolute;
    width: 100%;
    background: #ffffff;
    color : #000000;
    z-index: 10000;
    text-align: left;
    border: none;
    border-top: none;
    max-height:  160px;
    overflow-y:  auto;
    padding: 7px 0px
  }
  .v-autocomplete .v-autocomplete-list .v-autocomplete-not-data{
    padding: 13px 15px;
  }


 .v-autocomplete>.v-autocomplete-input-group>.v-autocomplete-input::placeholder {
    color: rgba(40,40,40,0.6);
  }

  .v-autocomplete>.v-autocomplete-list>.v-autocomplete-list-item{
    cursor:pointer;
    padding: 13px 15px;
  }

  .v-autocomplete>.v-autocomplete-list>.v-autocomplete-list-item:hover{
    background: rgba(40,40,40,0.1) !important;
  }

  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item.v-autocomplete-item-active {
    background: rgba(40,40,40,0.3) !important;
  }
</style>