import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/styles.scss"
// import "./assets/css/web.scss"
import vuetify from './plugins/vuetify'

import VueMask from 'v-mask'
 
// import Autocomplete from 'v-autocomplete'

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-autocomplete/dist/v-autocomplete.css'

// Vue.use('vue-autocmople',Autocomplete)

Vue.use(VueMask);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})
.$mount('#app')
