<template>
  <div class="step__scan">
    <div class="step__scan__camera" v-if="showScan">
      <div id="UIElement">
        <div class="camera-container">
          <div class="overlay-camera-1">
            <img src="@/assets/images/barcode.png" />
          </div>
        </div>
        <span class="loading" v-if="!libLoaded">Loading Library...</span>
        <BarcodeScanner
          v-if="bShowScanner"
          v-on:appendMessage="appendMessage"
        ></BarcodeScanner>
      </div>
      <a class="step__scan__skip__buttom" @click="goToStep(1)">Skip scan</a>
    </div>

    <h1 class="step__scan__title">Scan Driver's License</h1>
    <p class="step__scan__description">
      Scanning your driver's license will speed up the process of gathering your
      basic information. Please scan the 2D barcode on the back of your license.
    </p>
    <div class="step__scan__codebar">
      <img src="@/assets/images/barcode.png" />
    </div>
    <h2 class="step__scan__subtitle">2D Barcode</h2>
    <p class="step__scan__subdescription">Contains Identification Data</p>
    <v-btn class="step__scan__button" @click="scanLoad">Scan Now</v-btn>
    <a class="step__scan__skip" @click="goToStep(1)">Skip scan</a>
  </div>
</template>
<script>
import DBR from "@/dbr";
import BarcodeScanner from "@/components/BarcodeScanner";
import moment from "moment";
moment.locale("en");
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      resultValue: "",
      resultItems: [],
      libLoaded: false,
      bShowScanner: false,
      showScan: false,
    };
  },
  components: {
    BarcodeScanner,
  },

  mounted() {
    console.log("navigator.language: " + navigator.language);
  },
  methods: {
    ...mapMutations(["setScanData"]),
    async scanLoad() {
      this.showScan = true;
      // console.log("======>");
      try {
        await DBR.BarcodeScanner.loadWasm();

        this.libLoaded = true;
        this.showScanner();
      } catch (ex) {
        alert(ex.message);
        throw ex;
      }
    },
    goToStep(n) {
      this.$emit("goToStep", n);
    },
    appendMessage(message) {
      switch (message.type) {
        case "result":
          this.setDataScan(message.text);
          this.resultValue = message.format + ": " + message.text;
          this.resultItems.push({
            type: message.format + ": ",
            text: message.text,
          });
          // alert(message.format + ": " + message.text);
          // console.log(this.resultItems);
          console.log("format: " + message.format);
          if (message.format == "PDF417") {
            this.goToStep(1);
          }
          break;
        case "error":
          this.resultValue = message.msg;
          this.resultItems.push({ type: "Error: ", text: message.msg });
          break;
        default:
          break;
      }
    },
    showScanner(data) {
      this.bShowScanner = true;
    },
    setDataScan(data) {
      // if(this.state.isScanning){
      //     return;
      // }

      // this.setState({ isScanning: true });
      // this.setState({ isScanning: true });
      //Expo.ScreenOrientation.allow(Expo.ScreenOrientation.Orientation.LANDESCAPE_UP);

      // alert(data);
      console.log("data -->", data);

      let barcodeDataArray = data.split("\n");
      let driverLicense = "";
      let dateOfBirth = "";
      let addressOne = "",
        addressTwo = "",
        fullname = "",
        zipcode = "",
        cityName;
      let firstName = "",
        lastName = "",
        middleName = "";
      let stateAbbr = "";

      barcodeDataArray.forEach(function (item) {
        if (item.substring(0, 3).toUpperCase() == "DAJ")
          stateAbbr = item.substring(3, item.length);

        if (item.indexOf("DAQ") != -1 && driverLicense == "")
          driverLicense = item.substring(item.indexOf("DAQ") + 3, item.length);

        if (item.substring(0, 3).toUpperCase() == "DBB")
          dateOfBirth = item.substring(3, item.length);

        console.log(dateOfBirth);

        if (item.substring(0, 3).toUpperCase() == "DAG")
          addressOne = item.substring(3, item.length);

        if (item.substring(0, 3).toUpperCase() == "DAH")
          addressTwo = item.substring(3, item.length);

        if (item.substring(0, 3).toUpperCase() == "DAK")
          zipcode = item.substring(3, 8);

        if (item.substring(0, 3).toUpperCase() == "DAI")
          cityName = item.substring(3, item.length);

        if (item.indexOf("DAA") != -1)
          fullname = item.substring(item.indexOf("DAA") + 3, item.length);

        if (item.indexOf("DAC") != -1)
          firstName = item.substring(item.indexOf("DAC") + 3, item.length);

        if (item.indexOf("DBP") != -1)
          firstName = item.substring(item.indexOf("DBP") + 3, item.length);

        if (item.indexOf("DCT") != -1)
          firstName = item.substring(item.indexOf("DCT") + 3, item.length);

        if (item.indexOf("DCS") != -1)
          lastName = item.substring(item.indexOf("DCS") + 3, item.length);

        if (item.indexOf("DAB") != -1)
          lastName = item.substring(item.indexOf("DAB") + 3, item.length);

        if (item.indexOf("DBO") != -1)
          lastName = item.substring(item.indexOf("DBO") + 3, item.length);

        if (item.indexOf("DAD") != -1)
          middleName = item.substring(item.indexOf("DAD") + 3, item.length);

        if (item.indexOf("DBQ") != -1)
          middleName = item.substring(item.indexOf("DBQ") + 3, item.length);

        if (item.indexOf("DDG") != -1)
          middleName = item.substring(item.indexOf("DDG") + 3, item.length);
      });

      let arrayName = fullname.split(",");

      if (arrayName.length > 2) {
        if (firstName == "") firstName = arrayName[1];

        if (lastName == "") lastName = arrayName[0];

        if (middleName == "") middleName = arrayName[2];
      }

      let dateOfBirthCheck = moment(
        dateOfBirth.substring(4, 6) +
          "-" +
          dateOfBirth.substring(6, 8) +
          "-" +
          dateOfBirth.substring(0, 4),
        "MM-DD-YYYY"
      );

      if (!dateOfBirthCheck.isValid()) {
        let dateOfBirthCheck = moment(
          dateOfBirth.substring(0, 2) +
            "-" +
            dateOfBirth.substring(2, 4) +
            "-" +
            dateOfBirth.substring(4, 8),
          "MM-DD-YYYY"
        );

        if (!dateOfBirthCheck.isValid()) dateOfBirth = "";
        else {
          dateOfBirth =
            dateOfBirth.substring(0, 2) +
            "-" +
            dateOfBirth.substring(2, 4) +
            "-" +
            dateOfBirth.substring(4, 8);
        }
      } else {
        dateOfBirth =
          dateOfBirth.substring(4, 6) +
          "-" +
          dateOfBirth.substring(6, 8) +
          "-" +
          dateOfBirth.substring(0, 4);
      }

      let user = {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        dob: dateOfBirth,
        address_one: addressOne,
        address_two: addressTwo,
        zipcode: zipcode,
        city: cityName,
        dl_number: driverLicense,
        state_abbr: stateAbbr,
      };
      console.log(user);

      this.setScanData(user);
      // this.setState({ display_modal: !this.state.display_modal, });
      //isScanning:false
      //this.setState({ isScanning: true });
      // this.props.navigation.navigate('StepOneFL', { user: user });
    },
  },
};
</script>
