export default {
    state: {
        scanData: null,
        stepOneData: null,
        oneData: null,
        stepTwoData: null,
        stepThreeData: null,
        threeData: null,
        stepFourData: null,
        stepFiveData: null,
        stateSelected: null,
        // successReponse: null
    },
    getters: {
        scanData: (state) => state.scanData,
        stepOneData: (state) => state.stepOneData,
        oneData: (state) => state.oneData,
        stepTwoData: (state) => state.stepTwoData,
        stepThreeData: (state) => state.stepThreeData,
        threeData: (state) => state.threeData,
        stepFourData: (state) => state.stepFourData,
        stepFiveData: (state) => state.stepFiveData,
        stateSelected: (state) => state.stateSelected,
        // successReponse: (state) => state.successReponse,
    },
    mutations: {
        setScanData(state, payload) {
            state.scanData = payload;
        },
        setOneData(state, payload) {
            state.oneData = payload;
        },
        setStepOneData(state, payload) {
            state.stepOneData = payload;
        },
        setStepTwoData(state, payload) {
            state.stepTwoData = payload;
        },
        setStepThreeData(state, payload) {
            state.stepThreeData = payload;
        },
        setThreeData(state, payload) {
            state.threeData = payload;
        },
        setStepFourData(state, payload) {
            state.stepFourData = payload;
        },
        setStepFiveData(state, payload) {
            state.stepFiveData = payload;
        },
        setStateSelected(state, payload) {
            state.stateSelected = payload;
        },
        // setSuccessResponse(state, payload) {
        //     state.successReponse = payload;
        // },
    },
    actions: {
    },
}
