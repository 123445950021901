<template>
  <div class="steps">
    <div class="steps-items">
      <div class="v-stepper__content">
        <div class="step">
          <h1 class="step__title">Got a question about a traffic ticket?</h1>
          <v-form>
            <v-container>
              <v-row class="mb-5">
                <v-col cols="12" sm="12" class="form-text-yellow">
                  What is the nature of this request?
                </v-col>
                <v-col cols="12" sm="12">
                  <v-radio-group v-model="request" mandatory row>
                    <v-radio
                      label="I need help with a Ticket"
                      value="help"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="All other inquiries"
                      value="other"
                      :ripple="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="name"
                    placeholder="First Name"
                    solo
                    :error-messages="nameErrors"
                    @change="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    placeholder="Email Address"
                    solo
                    :error-messages="emailErrors"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    type="number"
                    v-model="phone"
                    placeholder="Phone Number"
                    solo
                    :error-messages="phoneErrors"
                    @input="$v.phone.$touch()"
                    @blur="$v.phone.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="request == 'help'">
                <v-col cols="12" sm="12">
                  <v-text-field
                    placeholder="Driver License Number"
                    solo
                    v-model="license"
                    :error-messages="licenseErrors"
                    @change="$v.license.$touch()"
                    @blur="$v.license.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="request == 'help'">
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="ticketType"
                    label="What type of ticket do you need help with?"
                    solo
                    :items="ticketTypeList"
                    item-text="name_en"
                    item-value="id"
                    return-object
                    :error-messages="ticketTypeErrors"
                    @change="$v.ticketType.$touch()"
                    @blur="$v.ticketType.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="request == 'help'">
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="state"
                    label="What state did you receive this ticket in?"
                    solo
                    :items="states"
                    item-text="name"
                    item-value="id"
                    return-object
                    :error-messages="stateErrors"
                    @change="$v.state.$touch()"
                    @blur="$v.state.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="request == 'help'">
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    label="County"
                    solo
                    :items="counties"
                    item-text="name"
                    item-value="id"
                    return-object
                    v-model="countie"
                    :error-messages="countieErrors"
                    @change="$v.countie.$touch()"
                    @blur="$v.countie.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-textarea
                    label="How can we assist you?"
                    v-model="message"
                    solo
                    :error-messages="messageErrors"
                    @input="$v.message.$touch()"
                    @blur="$v.message.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="mb-5 mt-5">
                <v-col cols="12" sm="12" class="form-text-yellow">
                  Upload a photo of your ticket
                </v-col>
              </v-row>
              <v-row class="mb-5 mt-5">
                <v-col cols="12" sm="12">
                  <!--img src="@/assets/images/upload-file.png" /-->
                  <div v-if="files.length" class="uploader">
                    <div
                      class="uploader__files"
                      v-for="file in files"
                      :key="file.id"
                    >
                      <div class="uploader__files__file" v-if="!image">
                        {{ file.name }}
                      </div>
                      <div class="uploader__files__image" v-if="image">
                        <img :src="image.src" />

                        <div
                          class="uploader__files__image__delete"
                          @click="deleteFile()"
                        >
                          <img src="@/assets/images/modal-close.svg" />
                        </div>
                      </div>
                      <div
                        v-if="!image"
                        class="uploader__files__delete"
                        @click="deleteFile()"
                      >
                        <img src="@/assets/images/modal-close.svg" />
                      </div>
                    </div>
                  </div>
                  <file-upload
                    v-else
                    :drop="true"
                    v-model="files"
                    ref="upload"
                    @input-file="inputFile()"
                  >
                    <div class="uploader">
                      <div class="uploader__box">
                        <div class="uploader__box__top">
                          <img src="@/assets/images/insert_photo.png" />Select
                          Files or
                        </div>
                        <div class="uploader__box__bottom">
                          Drop files anywhere to upload
                        </div>
                      </div>
                    </div>
                  </file-upload>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-btn
                    class="step__scan__button"
                    block
                    @click="submit"
                    :loading="sending"
                    >Continue</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" width="400px" persistent>
      <v-card>
        <v-card-title class="text-h5"> THANK YOU! </v-card-title>
        <v-card-text>
          <p>
            Thank You! So that we may confirm via e-mail, please make sure
            theticketclinic.com is not in your “blocked” sender list.
          </p>

          <p>
            A Representative from The Ticket Clinic will be in contact with you
            soon. Please call us at 1-800-CITATION(1-800-248-2846) if you have
            any immediate questions.
          </p>

          <p>
            If we do not get back to you within 48 hours, please call one of our
            office locations to confirm receipt of your online hire form!
          </p>

          <p>We appreciate your business.</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="onReload"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import FileUpload from "vue-upload-component";

const {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
} = require("vuelidate/lib/validators");

const validateIf = (prop, validator) =>
  helpers.withParams({ type: "validatedIf", prop }, function (value, parentVm) {
    return helpers.ref(prop, this, parentVm) ? validator(value) : true;
  });

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      request: "help",
      name: "",
      email: "",
      phone: "",
      message: "",
      license: "",
      state: null,
      countie: null,
      ticketType: null,
      states: [],
      counties: [],
      sending: false,
      ticketTypeList: [
        { id: "Speeding", name_en: "Speeding" },
        { id: "Red Light", name_en: "Red Light" },
        { id: "Suspended License", name_en: "Suspended License" },
        { id: "DUI", name_en: "DUI" },
        { id: "Other", name_en: "Other" },
      ],
      dialog: false,
      files: [],
      image: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: { required },
    message: { required },
    email: { required, email },
    phone: { required, minLength: minLength(10), maxLength: maxLength(10) },
    state: {
      requiredIf: requiredIf(function () {
        return this.request == "help";
      }),
    },
    countie: {
      requiredIf: requiredIf(function () {
        return this.request == "help";
      }),
    },
    license: {
      requiredIf: requiredIf(function () {
        return this.request == "help";
      }),
    },
    ticketType: {
      requiredIf: requiredIf(function () {
        return this.request == "help";
      }),
    },
  },
  methods: {
    deleteFile() {
      this.image = null;
      this.files = [];
    },
    inputFile() {
      this.image = null;

      if (
        this.files[0].file &&
        (this.files[0].file.type == "image/jpeg" ||
          this.files[0].file.type == "image/png")
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(this.files[0].file);
        let that = this;
        reader.onload = function () {
          // let preview = document.getElementById('feedImg'),
          let image = document.createElement("img");

          image.src = reader.result;
          console.log(image);
          that.image = image;
          // preview.innerHTML = '';
          // preview.append(image);
        };
      }
    },
    onReload() {
      this.dialog = false;
      window.location.href = process.env.VUE_APP_WEB_URL;
      // location.reload();
    },
    submit() {
      this.$v.$touch();
      let that = this;
      if (!this.$v.$invalid) {
        this.sending = true;
        setTimeout(function () {
          this.sending = false;
          that.dialog = true;
        }, 3000);
      }
    },
    loadStates() {
      this.states = [];
      axios
        .get(`v1/states`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.states = res.data;
            this.searchState();
          }
        });
    },
    loadCounties(state) {
      this.counties = [];
      axios
        .get(`v1/counties?state=${state}`)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          if (res) {
            this.counties = res.data;
          }
        });
    },
    searchState() {
      if (this.$route.query.state) {
        let stateCode = this.$route.query.state.toString().toUpperCase();

        let findResult = this.states.findIndex((x) => x.iso_code == stateCode);

        if (findResult > -1) {
          this.state = this.states[findResult];
          this.loadCounties(this.state.id);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["stateSelected"]),

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Firts Name can't be empty.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message can't be empty.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email can't be empty.");
      !this.$v.email.email && errors.push("Invalid Email.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone Number can't be empty.");
      !this.$v.phone.minLength && errors.push("Invalid Phone Number.");
      !this.$v.phone.maxLength && errors.push("Invalid Phone Number.");
      return errors;
    },
    licenseErrors() {
      const errors = [];
      if (!this.$v.license.$dirty) return errors;
      !this.$v.license.requiredIf &&
        errors.push("Driver License can't be empty.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.requiredIf && errors.push("State issued can't be empty.");
      return errors;
    },
    countieErrors() {
      const errors = [];
      if (!this.$v.countie.$dirty) return errors;
      !this.$v.countie.requiredIf &&
        errors.push("Countie issued can't be empty.");
      return errors;
    },
    ticketTypeErrors() {
      const errors = [];
      if (!this.$v.ticketType.$dirty) return errors;
      !this.$v.ticketType.requiredIf &&
        errors.push("Type of ticket can't be empty.");
      return errors;
    },
  },
  mounted() {
    this.loadStates();
    if (this.stateSelected) {
      this.state = this.stateSelected;
    }
  },
  watch: {
    state(val) {
      if (val) {
        this.loadCounties(val.id);
      } else {
        this.counties = [];
      }
    },
  },
};
</script>
